<form [formGroup]="cepForm">
  <div class=" form-row">
    <div class="campo-do-cep">
      <label for="cep">CEP:</label><br>
      <input class="pesquisa-cep" type="text" id="cep" formControlName="cep" (blur)="consultaCep()"  mask="00000-000">
      <small class="alerta" *ngIf="cep && !cep.valid">Cep inválido</small>
    </div>
    <div class="campo-rua">
      <label for="logradouro">RUA</label><br>
      <input type="text" class="pesquisa-rua" id="logradouro" formControlName="logradouro" placeholder="...">
    </div>
    <div class="campo-bairro">
      <label for="bairro">BAIRRO</label><br>
      <input type="text" class="pesquisa-bairro" id="bairro" formControlName="bairro" placeholder="...">
    </div>
    <div class="campo-uf">
      <label for="uf">UF</label><br>
      <input type="text" class="pesquisa-uf" id="uf" formControlName="uf" placeholder="...">
    </div>
    <div class="campo-cidade">
      <label for="cidade">CIDADE</label><br>
      <input type="text" class="pesquisa-cidade" id="cidade" formControlName="cidade" placeholder="...">

    </div>

    <div class="campo-numero">
      <label for="numero">NÚMERO</label><br>
      <input type="text" class="pesquisa-numero" id="numero" formControlName="numero">
    </div>
    <div class="campo-complemento">
      <label for="complemento">COMPLEMENTO</label><br>
      <input type="text" class="pesquisa-complemento" id="complemento" formControlName="complemento">
    </div>
    </div>
</form>
