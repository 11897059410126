<div uk-modal id="leave-modal">
  <div class="uk-modal-dialog uk-width-auto">
    <button class="uk-modal-close-outside" type="button" uk-close></button>
    <img [src]="leavePopupImage" alt="popupImg" />
  </div>
</div>

<div class="top-bar">
  <div class="container">
    <div class="row">
      <div class="uk-background-cover" style="padding: 5px 0">
        <img
          src="assets/icons/truck_icon.png"
          width="30"
          height="12"
          alt="truck-icon"
        />
        <span
          class="frete uk-text-uppercase uk-text-light uk-text-small"
          style="color: #ffffff"
          >Frete grátis</span
        >
        <span class="uk-text-emphasis uk-text-small">
          a partir de R$ 250,00</span
        >
        <span
          class="frete-info"
          uk-icon="info"
          style="color: #333"
          (click)="showInfo()"
        ></span>
      </div>
    </div>
  </div>
</div>

<!-- Busca em modo responsivo -->
<div #fixedMobile class="d-none mobile-nav" style="background-color: #ffffff">
  <div
    class="title d-flex align-items-center justify-content-between px-4 py-2 header-mobile"
  >
    <div class="uk-text-start" style="width: 20%">
      <span
        uk-toggle="target: #offcanvas-overlay"
        uk-icon="table"
        style="width: 2.5rem; color: #ff6060"
      ></span>
    </div>

    <div
      style="width: 60%"
      routerLink="/"
      class="text-decoration-none text-dark d-flex align-items-center teste"
    >
      <img class="logo" src="assets/imagens/LOGO EXALLA - DEGRADÊ.png" />

      <h4 class="font-weight-bold text-success m-0"></h4>
    </div>
    <div
      style="
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: end;
        width: 20%;
      "
    >
      <div style="text-align: end">
        <span
          uk-icon="heart"
          style="width: 2.5rem; color: #ff6060"
          routerLink="/favoritos"
        ></span>
      </div>
      <div style="text-align: end; position: relative">
        <span
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 0.8rem;
            height: 0.8rem;
            font-size: 0.6rem;
            background-color: #ff6060;
            border-radius: 100%;
            position: absolute;
            top: -0.5rem;
            right: -0.5rem;
            color: #ffffff;
          "
          >{{ qtdCarrinho }}</span
        >
        <span
          uk-icon="cart"
          style="width: 2.5rem; color: #ff6060"
          routerLink="/carrinho"
        ></span>
      </div>
    </div>

    <div #offCanvas id="offcanvas-overlay" uk-offcanvas="overlay: true">
      <div class="uk-offcanvas-bar bg-light" style="width: 230px !important">
        <button class="uk-offcanvas-close" type="button" uk-close></button>

        <app-menu-responsivo [offCanvas]="offCanvas"></app-menu-responsivo>
      </div>
    </div>
  </div>
  <div class="text-decoration-none px-3 py-3" #searchInputMobile>
    <div class="input-group rounded shadow-sm overflow-hidden bg-white">
      <div class="input-group-prepend">
        <button
          (click)="buscar()"
          class="border-0 btn btn-outline-secondary text-success bg-white"
        >
          <i class="icofont-search"></i>
        </button>
      </div>
      <input
        [(ngModel)]="search"
        (keyup.enter)="buscar()"
        type="text"
        class="shadow-none border-0 form-control pl-0"
        placeholder="Buscar Produto ..."
        aria-label=""
        aria-describedby="basic-addon1"
      />
    </div>
    <div *ngIf="search.length >= 3" class="pesquisa">
      <app-busca-produtos
        [search]="search"
        (filtrar)="limpar()"
      ></app-busca-produtos>
    </div>
  </div>
</div>

<div
  #fixed
  (scroll)="onWindowScroll($event)"
  class="bg-white shadow-sm osahan-main-nav"
>
  <nav
    class="row justify-content-center m-auto align-items-center py-0 uk-container-large"
  >
    <div class="col-3">
      <a class="navbar-brand mr-0" routerLink="/">
        <img
          class="img-fluid logo-img"
          src="assets/imagens/LOGO EXALLA - DEGRADÊ.png"
        />
      </a>
    </div>
    <div class="col-6 d-flex justify-content-center align-items-center">
      <!-- Busca em modo web -->

      <div>
        <div class="input-group mr-sm-5">
          <input
            #searchInput
            [(ngModel)]="search"
            type="text"
            (keydown.enter)="buscar($event)"
            class="form-control"
            placeholder="Buscar produto ..."
          />
          <div class="input-group-prepend">
            <div (click)="buscar()" class="btn btn-success rounded-right">
              <i class="icofont-search"></i>
            </div>
          </div>
        </div>
        <div #previewElement *ngIf="search.length > 3" class="pesquisa">
          <app-busca-produtos
            [search]="search"
            (filtrar)="limpar()"
          ></app-busca-produtos>
        </div>
      </div>
    </div>
    <div class="col-3 d-flex justify-content-end align-items-center">
      <!-- my account -->
      <div class="dropdown mr-3">
        <a
          class="dropdown-toggle text-dark uk-text-small"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1em;
          "
        >
          <!-- <img
            src="assets/icons/usuarios.png"
            class="img-fluid rounded-circle header-user mr-2"
          />
          {{ nomeCliente ? nomeCliente.split(' ')[0] : '' }} -->
          <svg
            fill="#ff6060 "
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="32px"
            height="32px"
            viewBox="0 0 45.532 45.532"
            xml:space="preserve"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke="#000"
              stroke-width="0.273192"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <g>
                <path
                  d="M22.766,0.001C10.194,0.001,0,10.193,0,22.766s10.193,22.765,22.766,22.765c12.574,0,22.766-10.192,22.766-22.765 S35.34,0.001,22.766,0.001z M22.766,6.808c4.16,0,7.531,3.372,7.531,7.53c0,4.159-3.371,7.53-7.531,7.53 c-4.158,0-7.529-3.371-7.529-7.53C15.237,10.18,18.608,6.808,22.766,6.808z M22.761,39.579c-4.149,0-7.949-1.511-10.88-4.012 c-0.714-0.609-1.126-1.502-1.126-2.439c0-4.217,3.413-7.592,7.631-7.592h8.762c4.219,0,7.619,3.375,7.619,7.592 c0,0.938-0.41,1.829-1.125,2.438C30.712,38.068,26.911,39.579,22.761,39.579z"
                ></path>
              </g>
            </g>
          </svg>
          {{ nomeCliente ? nomeCliente.split(" ")[0] : "" }}
        </a>

        <div
          class="dropdown-menu dropdown-menu-right top-profile-drop"
          aria-labelledby="dropdownMenuButton"
        >
          <section *ngIf="!this.socialLoginService.isAuthenticated()">
            <div class="authentication-login">
              <a [routerLink]="['/signin']" class="my-account">
                <button class="button-my-account">
                  Olá! Entrar na Minha Conta
                </button>
              </a>
            </div>
            <div class="not-register">
              <h5>Não tem cadastro?</h5>
              <a class="link" [routerLink]="['/signup']"> Criar Conta </a>
            </div>
          </section>
          <section *ngIf="this.socialLoginService.isAuthenticated()">
            <a class="dropdown-item" [routerLink]="['/minha-conta']"
              >Minha Conta</a
            >
            <a class="dropdown-item" [routerLink]="['/meus-pedidos']"
              >Meus Pedidos</a
            >
            <a class="dropdown-item" [routerLink]="['/my-address']"
              >Meus Endereços</a
            >
            <a class="dropdown-item" [routerLink]="['/']" (click)="logOut()"
              >Sair</a
            >
          </section>
        </div>
      </div>
      <!-- notificação se usuario tiver logado  -->
      <!-- notificação se o usuario não estiver logado -->
      <div class="dropdown">
        <a
          [routerLink]="
            !this.socialLoginService.accountData ? ['/signin'] : ['/produtos']
          "
          [queryParams]="{ favorito: 'true' }"
        >
          <span
            class="text dropdown-toggle not-drop"
            id="dropdownMenuNotification"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i
              uk-icon="heart"
              class="heart uk-text-bold d-flex align-items-center bg-light rounded-pill p-2 icofont-size border shadow-sm"
            ></i>
          </span>
        </a>
      </div>
      <!-- cart -->
      <div>
        <a
          [routerLink]="['/carrinho']"
          class="cart-icon ml-2 text-dark bg-light rounded-pill p-2 icofont-size border shadow-sm"
        >
          <box-icon name="basket"></box-icon>
          <span *ngIf="this.qtdCarrinho > 0" class="uk-badge">
            {{ this.qtdCarrinho }}
          </span>
        </a>
      </div>
    </div>
  </nav>
</div>

<div #dialogInfo class="uk-flex-top" uk-modal>
  <div
    class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical"
    style="border-radius: 0.5rem"
  >
    <button class="uk-modal-close-default" type="button" uk-close></button>
    <p style="font-size: 1.2rem; color: #8e9091">
      <span style="color: #ff6060; font-weight: bold; font-size: 1.5rem"
        >Exallover</span
      >, o nosso frete é gratuito a partir de compras de <b>R$250,00</b> para
      Fortaleza, Maracanaú e Eusébio. Essa condição não é válida para a compra
      de móveis, que tem uma taxa fixa de <b>R$50,00</b>, independente do valor
      da compra.
    </p>
  </div>
</div>

<!-- Menu bar -->
<div class="menu-bar">
  <app-departamento></app-departamento>
</div>
