import { Injectable } from '@angular/core';
import { Address } from '../../../types/index';
import { IAccountAdress } from 'src/app/model/account.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnderecoService {
  address : IAccountAdress [] = [];
  addressSubject$ = new Subject<any>();

  constructor() {

  }

}
