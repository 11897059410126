import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IFinalizarPagamento, Pagamento } from '../../model/pagamento';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PagamentoService {
  constructor(public httpClient: HttpClient) {}

  enviarCadastro(
    request: IFinalizarPagamento,
    cod_vendedor?: string,
    cod_cupom?: string
  ) {
    let data;
    if (cod_cupom && cod_vendedor) {
      data = {
        ...request,
        cod_vendedor: cod_vendedor.split(')')[0].replace('(', ''),
        cod_cupom: cod_cupom,
      };
    } else if (cod_cupom) {
      data = { ...request, cod_cupom: cod_cupom };
    } else if (cod_vendedor) {
      data = {
        ...request,
        cod_vendedor: cod_vendedor.split(')')[0].replace('(', ''),
      };
    } else {
      data = request;
    }
    return this.httpClient.post(`${environment.api}/payment/flow`, data);
  }

  verificarPix(request: any): Observable<any> {
    return this.httpClient.post(
      `${environment.api}/payment/pix-status`,
      request
    );
  }
}
