import { QRCodeModule } from 'angularx-qrcode';
import { NgxMaskModule } from 'ngx-mask';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepartamentoComponent } from './componentes/departamento/departamento.component';
import { ListingComponent } from './componentes/listing/listing.component';
import { FooterComponent } from './componentes/footer/footer.component';
import { HeadComponent } from './componentes/head/head.component';
import { PickSlideComponent } from './componentes/picks-slide/pick-slide.component';
import { RouterModule } from '@angular/router';
import { RecommendSlideComponent } from './componentes/recommend-slide/recommend-slide.component';
import { PromoForYouComponent } from './componentes/promo-for-you/promo-for-you.component';
import { TelaDeUsuarioComponent } from './componentes/tela-de-usuario/tela-de-usuario.component';
import { PicksTodayComponent } from './componentes/picks-today/picks-today.component';
import { CardsPromoComponent } from './componentes/cards-promo/cards-promo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BuscaProdutosComponent } from './componentes/busca-produtos/busca-produtos.component';
import { BannerComponent } from './componentes/banner/banner.component';
import { FiltroComponent } from './componentes/filtro/filtro.component';
import { CadastroDeEnderecoComponent } from './componentes/cadastro-de-endereco/cadastro-de-endereco.component';
import { MeusEnderecosComponent } from './componentes/meus-enderecos/meus-enderecos.component';
import { CartaoDeCreditoComponent } from './componentes/cartao-de-credito/cartao-de-credito.component';
import { CadastroMaxPagoComponent } from './componentes/cadastro-max-pago/cadastro-max-pago.component';
import { ProdutoComponent } from './componentes/produto/produto.component';
import { BreadcrumbComponent } from './componentes/breadcrumb/breadcrumb.component';
import { MenuResponsivoComponent } from './componentes/menu-responsivo/menu-responsivo.component';
import { CepComponent } from './componentes/cep/cep.component';
import { CadastroParaPagamentoComponent } from './componentes/cadastro-para-pagamento/cadastro-para-pagamento.component';
import { PagamentoPixComponent } from './componentes/pagamento-pix/pagamento-pix.component';
import { BottomMenuComponent } from './componentes/bottom-menu/bottom-menu.component';
import { SlideModalComponent } from './componentes/slide-modal/slide-modal.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BannersVerticaisComponent } from './componentes/banners-verticais/banners-verticais.component';
import { SafePipe } from './pipes/safe.pipe';
import { GenericModalComponent } from './componentes/generic-modal/generic-modal.component';
import { ImagemAuthDirective } from './directives/imagem-auth.directive';
import { WhatsappFloatButtonComponent } from './componentes/whatsapp-float-button/whatsapp-float-button.component';
import { RestrictSpecialCharsDirective } from './directives/no-special-chars.directive';

@NgModule({
  declarations: [
    DepartamentoComponent,
    ListingComponent,
    FooterComponent,
    HeadComponent,
    PickSlideComponent,
    RecommendSlideComponent,
    PromoForYouComponent,
    TelaDeUsuarioComponent,
    PicksTodayComponent,
    CardsPromoComponent,
    BuscaProdutosComponent,
    BannerComponent,
    CadastroDeEnderecoComponent,
    FiltroComponent,
    MeusEnderecosComponent,
    CartaoDeCreditoComponent,
    CadastroMaxPagoComponent,
    ProdutoComponent,
    BreadcrumbComponent,
    MenuResponsivoComponent,
    CepComponent,
    CadastroParaPagamentoComponent,
    PagamentoPixComponent,
    BottomMenuComponent,
    SlideModalComponent,
    BannersVerticaisComponent,
    SafePipe,
    GenericModalComponent,
    WhatsappFloatButtonComponent,
    ImagemAuthDirective,
    RestrictSpecialCharsDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild(),
    QRCodeModule,
    NgxSpinnerModule,
  ],
  exports: [
    GenericModalComponent,
    DepartamentoComponent,
    ListingComponent,
    FooterComponent,
    HeadComponent,
    PickSlideComponent,
    RecommendSlideComponent,
    PromoForYouComponent,
    TelaDeUsuarioComponent,
    PicksTodayComponent,
    CardsPromoComponent,
    BuscaProdutosComponent,
    BannerComponent,
    FiltroComponent,
    MeusEnderecosComponent,
    CartaoDeCreditoComponent,
    CadastroMaxPagoComponent,
    ProdutoComponent,
    BreadcrumbComponent,
    MenuResponsivoComponent,
    CepComponent,
    CadastroParaPagamentoComponent,
    PagamentoPixComponent,
    BottomMenuComponent,
    SlideModalComponent,
    NgxSpinnerModule,
    InfiniteScrollModule,
    CadastroDeEnderecoComponent,
    BannersVerticaisComponent,
    SafePipe,
    WhatsappFloatButtonComponent,
    ImagemAuthDirective,
    RestrictSpecialCharsDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
