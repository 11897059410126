<!-- <div *ngIf="!this.socialLoginService.accountData" class="d-flex flex-column justify-content-between h-50">
  <div class="d-flex flex-column text-center mt-5">
    <div class="authentication-login m-auto text-center">
      <a (click)="close()" [routerLink]="['/signin']" class="my-account">
        <button class="button-my-account">Olá! Entrar na Minha Conta</button>
      </a>
    </div>
    <div (click)="close()" class="link" [routerLink]="['/signup']" class="text-center mt-3"
      style="color: rgb(116, 3, 3)">
      Não tem cadastro? Criar Conta
    </div>
  </div>
  <div class="text-center mb-3 div-logo">
    <img class="logo-bottom" src="assets/imagens/LOGO PNG.png" />
  </div>
</div> -->

<div
  *ngIf="this.socialLoginService.accountData"
  class="d-flex flex-column justify-content-between"
>
  <div class="side-bar">
    <div style="display: flex; flex-direction: column; gap: 0.5rem">
      <div class="card-profile">
        <div class="profile-icon">
          <span uk-icon="icon: user; ratio: 2;"></span>
        </div>

        <div class="ml-3" *ngIf="nomeCliente">
          <span class="name">
            Olá, <br />
            {{ nomeCliente }}.
          </span>
        </div>
        <div class="ml-3" *ngIf="!nomeCliente">
          <div class="not-uthenticated">
            <span> Olá,</span>
            <br />
            <span>Seja bem-vindo!</span>
          </div>
        </div>
      </div>
      <div class="actions-main">
        <div
          *ngIf="this.socialLoginService.accountData.value"
          class="authenticated"
        >
          <div
            (click)="close()"
            [routerLink]="['/']"
            class="user-nav"
            style="display: flex; align-items: center; gap: 0.5rem"
          >
            <span uk-icon="home"></span>
            <span>INÍCIO</span>
          </div>
          <div
            (click)="close()"
            [routerLink]="['minha-conta']"
            class="user-nav"
            style="display: flex; align-items: center; gap: 0.5rem"
          >
            <span uk-icon="user"></span>
            <span>MINHA CONTA</span>
          </div>
          <div
            (click)="close()"
            [routerLink]="['meus-pedidos']"
            class="user-nav"
            style="display: flex; align-items: center; gap: 0.5rem"
          >
            <span uk-icon="bag"></span>
            <span>MEUS PEDIDOS</span>
          </div>
        </div>
        <div
          *ngIf="!this.socialLoginService.accountData.value"
          class="authenticated"
        >
          <div (click)="close()" [routerLink]="['signin']" class="user-nav">
            <span>FAÇA LOGIN</span>
          </div>
          <div (click)="close()" [routerLink]="['signup']" class="user-nav">
            <span>CADASTRE-SE</span>
          </div>
        </div>
        <div class="categories">
          <ul uk-accordion style="width: 100%">
            <li class="category" *ngFor="let d of departments">
              <b class="uk-accordion-title" style="color: #ff6060"
                ><span uk-icon="tag"></span> {{ d.description }}</b
              >

              <div class="uk-accordion-content">
                <ul style="margin: 0; padding: 0; width: 100%">
                  <li
                    *ngFor="let s of d.sub_groups"
                    class="content"
                    style="
                      display: flex;
                      justify-content: start;
                      align-items: center;
                      padding: 0.7rem;
                    "
                    (click)="close()"
                    routerLink="/produtos"
                    [queryParams]="{
                      cod_grupo: d.id,
                      cod_subgrupo: s.id
                    }"
                  >
                    <span style="margin-left: 1.5rem">
                      {{ s.description.toUpperCase() }}</span
                    >
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <!-- <div class="buttons">
          <div
            *ngFor="let button of buttons"
            class="button ofertas uk-flex uk-flex-middle uk-flex-center"
            style="width: 100%; height: 2.5rem; cursor: pointer"
            [ngStyle]="{
              display: buttons.length != 0 ? 'flex' : 'none',
              'background-color': button.cor_background,
              width: '10rem'
            }"
            [routerLink]="['/produtos']"
            [queryParams]="{ lista: button.lista }"
            (click)="close()"
          >
            <p style="margin: 0" *ngIf="!button.nome">Ofertas</p>
            <p
              style="margin: 0"
              *ngIf="button.nome"
              [ngStyle]="{
                color: button.cor_texto,
                'font-weight': button.peso_fonte * 10,
                'font-size': button.tamanho_fonte + 'px'
              }"
            >
              {{ button.nome }}
            </p>
          </div>
        </div> -->
        <div class="actions">
          <div
            *ngFor="let button of buttons"
            class="user-nav uk-flex"
            [routerLink]="['/produtos']"
            [queryParams]="{ lista: button.lista }"
            (click)="close()"
          >
            <span uk-icon="star"></span>
            <p style="margin: 0" *ngIf="!button.nome">Ofertas</p>
            <p style="margin: 0" *ngIf="button.nome">
              {{ button.nome }}
            </p>
          </div>

          <div class="user-nav uk-flex">
            <a
              href="https://www.facebook.com/exallacosmeticos/"
              class="icone-rede-social"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20"
                height="20"
                viewBox="0,0,256,256"
              >
                <g
                  fill="#ff6060"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="1"
                  stroke-linecap="butt"
                  stroke-linejoin="miter"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <g transform="scale(5.12,5.12)">
                    <path
                      d="M25,3c-12.13844,0 -22,9.86156 -22,22c0,11.01913 8.12753,20.13835 18.71289,21.72852l1.14844,0.17383v-17.33594h-5.19727v-3.51953h5.19727v-4.67383c0,-2.87808 0.69065,-4.77363 1.83398,-5.96289c1.14334,-1.18926 2.83269,-1.78906 5.18359,-1.78906c1.87981,0 2.61112,0.1139 3.30664,0.19922v2.88086h-2.44727c-1.38858,0 -2.52783,0.77473 -3.11914,1.80664c-0.59131,1.03191 -0.77539,2.264 -0.77539,3.51953v4.01758h6.12305l-0.54492,3.51953h-5.57812v17.36523l1.13477,-0.1543c10.73582,-1.45602 19.02148,-10.64855 19.02148,-21.77539c0,-12.13844 -9.86156,-22 -22,-22zM25,5c11.05756,0 20,8.94244 20,20c0,9.72979 -6.9642,17.7318 -16.15625,19.5332v-12.96875h5.29297l1.16211,-7.51953h-6.45508v-2.01758c0,-1.03747 0.18982,-1.96705 0.50977,-2.52539c0.31994,-0.55834 0.62835,-0.80078 1.38477,-0.80078h4.44727v-6.69141l-0.86719,-0.11719c-0.59979,-0.08116 -1.96916,-0.27148 -4.43945,-0.27148c-2.7031,0 -5.02334,0.73635 -6.625,2.40234c-1.60166,1.66599 -2.39258,4.14669 -2.39258,7.34961v2.67383h-5.19727v7.51953h5.19727v12.9043c-9.04433,-1.91589 -15.86133,-9.84626 -15.86133,-19.4707c0,-11.05756 8.94244,-20 20,-20z"
                    ></path>
                  </g>
                </g>
              </svg>
            </a>
            <a
              href="https://www.facebook.com/exallacosmeticos/"
              class="descricao-rede-social"
              target="_blank"
            >
              FACEBOOK
            </a>
          </div>
          <div class="user-nav uk-flex">
            <a
              href="https://twitter.com/exallabeleza"
              class="icone-rede-social"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20"
                height="20"
                viewBox="0,0,256,256"
              >
                <g
                  fill="#ff6060"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="1"
                  stroke-linecap="butt"
                  stroke-linejoin="miter"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <g transform="scale(5.12,5.12)">
                    <path
                      d="M5.91992,6l14.66211,21.375l-14.35156,16.625h3.17969l12.57617,-14.57812l10,14.57813h12.01367l-15.31836,-22.33008l13.51758,-15.66992h-3.16992l-11.75391,13.61719l-9.3418,-13.61719zM9.7168,8h7.16406l23.32227,34h-7.16406z"
                    ></path>
                  </g>
                </g>
              </svg>
            </a>
            <a
              href="https://twitter.com/exallabeleza"
              class="descricao-rede-social"
              target="_blank"
            >
              TWITTER
            </a>
          </div>
          <div class="user-nav uk-flex">
            <div>
              <a
                href="https://www.instagram.com/exallacosmeticos/"
                class="icone-rede-social"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="20"
                  height="20"
                  viewBox="0,0,256,256"
                >
                  <g
                    fill="#ff6060"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <g transform="scale(5.12,5.12)">
                      <path
                        d="M16,3c-7.16752,0 -13,5.83248 -13,13v18c0,7.16752 5.83248,13 13,13h18c7.16752,0 13,-5.83248 13,-13v-18c0,-7.16752 -5.83248,-13 -13,-13zM16,5h18c6.08648,0 11,4.91352 11,11v18c0,6.08648 -4.91352,11 -11,11h-18c-6.08648,0 -11,-4.91352 -11,-11v-18c0,-6.08648 4.91352,-11 11,-11zM37,11c-1.10457,0 -2,0.89543 -2,2c0,1.10457 0.89543,2 2,2c1.10457,0 2,-0.89543 2,-2c0,-1.10457 -0.89543,-2 -2,-2zM25,14c-6.06329,0 -11,4.93671 -11,11c0,6.06329 4.93671,11 11,11c6.06329,0 11,-4.93671 11,-11c0,-6.06329 -4.93671,-11 -11,-11zM25,16c4.98241,0 9,4.01759 9,9c0,4.98241 -4.01759,9 -9,9c-4.98241,0 -9,-4.01759 -9,-9c0,-4.98241 4.01759,-9 9,-9z"
                      ></path>
                    </g>
                  </g>
                </svg>
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/exallacosmeticos/"
                class="descricao-rede-social"
                target="_blank"
              >
                INSTAGRAM
              </a>
            </div>
          </div>
          <!-- <div class="user-nav uk-flex">
            <a
              href="https://dagdistribuidor.com.br/trabalhe-conosco/"
              class="icone-rede-social"
              target="_blank"
            >
              <i uk-icon="users"></i>
            </a>
            <a
              href="https://dagdistribuidor.com.br/trabalhe-conosco/"
              class="descricao-rede-social"
              target="_blank"
            >
              TRABALHE CONOSCO
            </a>
          </div> -->
          <div class="user-nav uk-flex" (click)="close()">
            <a routerLink="/nossas-lojas" class="icone-rede-social">
              <i uk-icon="location"></i>
            </a>
            <a routerLink="/nossas-lojas" class="descricao-rede-social">
              NOSSAS LOJAS
            </a>
          </div>
          <div class="categories">
            <ul uk-accordion style="width: 100%">
              <li class="category">
                <b class="uk-accordion-title" style="color: #ff6060"
                  ><span uk-icon="link"></span>LINKS ÚTEIS</b
                >
                <div class="uk-accordion-content">
                  <ul style="margin: 0; padding: 0; width: 100%">
                    <li
                      class="content"
                      style="
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        padding: 0.7rem;
                      "
                      (click)="close()"
                    >
                      <span style="margin-left: 1.5rem"
                        ><a
                          style="color: #cf0939"
                          href="https://dagdistribuidor.com.br/trabalhe-conosco/"
                          target="_blank"
                          >Trabalhe Conosco</a
                        >
                      </span>
                    </li>
                    <li
                      class="content"
                      style="
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        padding: 0.7rem;
                      "
                      (click)="close()"
                    >
                      <span style="margin-left: 1.5rem"
                        ><a
                          style="color: #cf0939"
                          href="https://www.facebook.com/exallacosmeticos/"
                          target="_blank"
                          >Sou Exalla</a
                        >
                      </span>
                    </li>
                    <li
                      class="content"
                      style="
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        padding: 0.7rem;
                      "
                      (click)="close()"
                      routerLink="/politicas-de-devolucao"
                    >
                      <span style="margin-left: 1.5rem"
                        >Políticas de Devolução</span
                      >
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>

          <div
            (click)="logOut()"
            class="user-nav"
            style="display: flex; align-items: center; gap: 0.5rem"
            *ngIf="this.socialLoginService.accountData.value"
          >
            <!-- <span class="sair-nav"><i class="icofont-exit"></i>&nbsp;Sair</span> -->
            <span uk-icon="sign-out"></span>
            <span class="sair-nav">SAIR</span>
          </div>
        </div>
      </div>
    </div>
    <div class="whatsapp">
      <div class="icon">
        <span uk-icon="icon: whatsapp; ratio: 1.5;" style="color: #fff"></span>
      </div>
      <a
        href="https://api.whatsapp.com/send?phone=5585991460459&text=Ol%C3%A1!%20Vim%20pelo%20site%20da%20Exalla."
        target="_blank"
      >
        <span style="color: #ffffff">Falar com a Exalla</span>
      </a>
    </div>
  </div>
</div>

<!-- <ul class="uk-nav" uk-nav>
  <li class="uk-active">
    <a (click)="onClick()" routerLink="/">Home</a>
  </li>

  <li *ngFor="let d of departments" class="uk-parent">
    <a href="#">{{ d.description }}</a>
    <ul class="uk-nav-sub">
      <li *ngFor="let s of d.sub_groups">
        <a
          (click)="onClick()"
          routerLink="/produtos"
          [queryParams]="{
            cod_grupo: d.id,
            cod_subgrupo: s.id
          }"
        >
          {{ s.description }}
        </a>
      </li>
    </ul>
  </li>

  <li>
    <a (click)="onClick()" [routerLink]="['/ofertas']"> Ofertas Imperdíveis </a>
  </li>
</ul> -->
