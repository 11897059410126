<div id="genericModal" uk-modal>
  <div class="uk-modal-dialog uk-modal-body generic-modal">
    <h2 class="uk-modal-title">{{ modalService.title }}</h2>
    <button
      (click)="closeModal()"
      class="uk-modal-close-default"
      type="button"
      uk-close
    ></button>
    <ng-container #modalContent></ng-container>
  </div>
</div>
