<div #box class="box py-5">
  <div class="uk-container">
    <h3 class="uk-header">Departamentos</h3>
    <hr />
    <ul class="uk-nav uk-list-striped" uk-nav>
      <li *ngFor="let d of departments" class="uk-parent">
        <a style="color: #ff6060; font-size: 1.2rem;">{{ d.description }}</a>
        <ul class="uk-nav-sub uk-list-divider">
          <li
            *ngFor="let s of d.sub_groups"
            routerLink="/produtos"
            [queryParams]="{
              cod_grupo: d.id,
              cod_subgrupo: s.id
            }"
            (click)="fecharModal()"
            style="font-size: 1rem;"
          >
            {{ s.description }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
