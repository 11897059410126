<div style="background-color: #e9ecef;">
  <div class="uk-container-large uk-margin-auto">
    <nav aria-label="breadcrumb" class="breadcrumb">
      <div class="breadcrumb-item">
        <a routerLink="/" class="text-success">
          <span uk-icon="home"></span>
        </a>
      </div>
      <div class="breadcrumb-item" *ngFor="let item of items; let i = index">
        <a [routerLink]="item.route" [queryParams]="item.queryParams">
          {{ item.label }}
        </a>
      </div>

      <app-filtro
        *ngIf="hasFilter && (productService.productList.length > 0 || !router.url.includes('favorito') || isUserFiltering)  "
        (elementsFiltrado)="order($event)"></app-filtro>
    </nav>
  </div>
</div>