import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { GoogleAnalytics } from '../interfaces/google-analytics.interfaces';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { firebaseMessagingConfig } from '../utils/firebase-config';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { ListaProduto } from 'src/app/model/listaproduto.model';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private googleAnalytics!: GoogleAnalytics;
  private app: any;
  private analytics: any;
  private itemsCart: any[] = [];
  private totalCart: number = 0;
  private subtotal: number = 0;
  public platform!: string;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.app = initializeApp(firebaseMessagingConfig);
      this.analytics = getAnalytics(this.app);
    }
  }

  setFbUserId(userId: string): void {
    if (this.analytics) {
      setUserId(this.analytics, userId);
    }
  }

  setFbScreenName(screenName: string, screenClass: string): void {
    if (isPlatformBrowser(this.platformId) && this.platform !== 'web') {
      FirebaseAnalytics.setScreenName({
        screenName: screenName,
        nameOverride: screenClass,
      });
    } else if (this.analytics) {
      logEvent(this.analytics, 'screen_view', {
        firebase_screen: screenName,
        firebase_screen_class: screenClass,
      });
    }
  }

  viewProduct(item: ListaProduto) {
    this.googleAnalytics = {
      name: 'view_item',
      params: {
        currency: 'BRL',
        value: item.valor_venda,
        items: [
          {
            item_id: item.cod_produto,
            item_name: item.descricao,
            item_category: item.descricao_grupo,
            item_category2: item.descricao_subgrupo,
          },
        ],
      },
    };
    if (isPlatformBrowser(this.platformId) && this.platform !== 'web') {
      FirebaseAnalytics.logEvent(this.googleAnalytics);
    } else if (this.analytics) {
      logEvent(
        this.analytics,
        this.googleAnalytics.name,
        this.googleAnalytics.params
      );
    }
  }

  addToWishlist(item: any) {
    this.googleAnalytics = {
      name: 'add_to_wishlist',
      params: {
        currency: 'BRL',
        value: item.preco_aplicado,
        items: [
          {
            item_id: item.sku,
            item_name: item.descricao_produto,
            affiliation: item.id_distribuidor?.toString(),
            coupon: '',
            currency: 'BRL',
            discount: 0,
            index: 1,
            item_brand: item.descricao_marca,
            item_category: 'TIPO ONDE O PRODUTO FOI ADICIONADO',
            item_category2: 'GRUPO DE ONDE O PRODUTO FOI ADICIONADO',
            item_category3: 'SUBGRUPO DE ONDE O PRODUTO FOI ADICIONADO',
            item_list_name: item.tipo_produto,
            item_variant: item.variante,
            price: item.preco_aplicado,
            quantity: item.quantidade,
          },
        ],
      },
    };
    if (isPlatformBrowser(this.platformId) && this.analytics) {
      logEvent(
        this.analytics,
        this.googleAnalytics.name,
        this.googleAnalytics.params
      );
    }
  }

  searchAnalytics(term: string) {
    this.googleAnalytics = {
      name: 'search',
      params: {
        search_term: term,
      },
    };
    if (isPlatformBrowser(this.platformId) && this.analytics) {
      logEvent(
        this.analytics,
        this.googleAnalytics.name,
        this.googleAnalytics.params
      );
    }
  }

  signUp(platform: string): void {
    this.googleAnalytics = {
      name: 'sign_up',
      params: {
        method: `Cadastro ${platform}`,
      },
    };
    if (isPlatformBrowser(this.platformId) && this.analytics) {
      logEvent(
        this.analytics,
        this.googleAnalytics.name,
        this.googleAnalytics.params
      );
    }
  }

  signIn(method: string): void {
    this.googleAnalytics = {
      name: 'login',
      params: {
        method: method,
      },
    };
    if (isPlatformBrowser(this.platformId) && this.analytics) {
      logEvent(
        this.analytics,
        this.googleAnalytics.name,
        this.googleAnalytics.params
      );
    }
  }

  viewCart() {
    this.totalCart = 0;
    this.subtotal = 0;
    this.googleAnalytics = {
      name: 'view_cart',
      params: {
        currency: 'BRL',
        value: this.totalCart,
        items: this.itemsCart,
      },
    };
    if (isPlatformBrowser(this.platformId) && this.analytics) {
      logEvent(
        this.analytics,
        this.googleAnalytics.name,
        this.googleAnalytics.params
      );
    }
    this.totalCart = 0;
    this.subtotal = 0;
    this.itemsCart = [];
  }

  addPaymentInfo(paymentInfo: any, items: any) {
    let distributor!: any;
    items.itens.forEach((item: any) => {
      this.addItemCart(item, distributor.nome_fantasia);
    });
    this.googleAnalytics = {
      name: 'add_payment_info',
      params: {
        currency: 'BRL',
        coupon: '',
        payment_type: paymentInfo.nome,
        items: this.itemsCart,
      },
    };
    if (isPlatformBrowser(this.platformId) && this.analytics) {
      logEvent(
        this.analytics,
        this.googleAnalytics.name,
        this.googleAnalytics.params
      );
    }
  }

  addCart(item: any, distribuidor?: string) {
    this.addItemCart(item, distribuidor!);
    this.googleAnalytics = {
      name: 'add_to_cart',
      params: {
        currency: 'BRL',
        value: item.preco_aplicado * item.quantidade,
        items: this.itemsCart,
        affiliation: distribuidor,
      },
    };
    if (isPlatformBrowser(this.platformId) && this.analytics) {
      logEvent(
        this.analytics,
        this.googleAnalytics.name,
        this.googleAnalytics.params
      );
    }
    this.itemsCart = [];
  }

  removeCart(item: any, distribuidor?: string) {
    this.googleAnalytics = {
      name: 'remove_from_cart',
      params: {
        currency: 'BRL',
        value: item.preco_aplicado,
        items: this.itemsCart,
        affiliation: distribuidor,
      },
    };
    if (isPlatformBrowser(this.platformId) && this.analytics) {
      logEvent(
        this.analytics,
        this.googleAnalytics.name,
        this.googleAnalytics.params
      );
    }
    this.itemsCart = [];
  }

  purchase(pedido: any) {
    let listaItens: any[] = [];
    for (let [indice, item] of pedido.itens.entries()) {
      listaItens.push({
        item_id: item.sku,
        item_name: item.descricao_produto,
        affiliation: pedido.id_distribuidor.toString(),
        coupon: '',
        currency: 'BRL',
        discount: 0,
        index: indice + 1,
        item_brand: item.descricao_marca,
        item_category: 'TIPO ONDE O PRODUTO FOI ADICIONADO',
        item_category2: 'GRUPO DE ONDE O PRODUTO FOI ADICIONADO',
        item_category3: 'SUBGRUPO DE ONDE O PRODUTO FOI ADICIONADO',
        item_list_name: item.tipo_produto,
        item_variant: item.variante,
        price: item.preco_aplicado,
        quantity: item.quantidade,
      });
    }
    this.googleAnalytics = {
      name: 'purchase',
      params: {
        currency: 'BRL',
        value: pedido.total,
        affiliation: pedido.id_distribuidor.toString(),
        coupon: '',
        shipping: pedido.frete,
        tax: 0,
        items: listaItens,
      },
    };
    if (isPlatformBrowser(this.platformId) && this.analytics) {
      logEvent(
        this.analytics,
        this.googleAnalytics.name,
        this.googleAnalytics.params
      );
    }
  }

  beginCheckout(cart: any) {
    this.googleAnalytics = {
      name: 'begin_checkout',
      params: {
        currency: 'BRL',
        value: cart.value,
        coupon: cart.coupon,
        items: [
          {
            item_id: 'SKU_12345',
            item_name: 'Stan and Friends Tee',
            affiliation: 'Google Store',
            coupon: 'SUMMER_FUN',
            currency: 'USD',
            discount: 2.22,
            index: 5,
            item_brand: 'Google',
            item_category: 'Apparel',
            item_category2: 'Adult',
            item_category3: 'Shirts',
            item_category4: 'Crew',
            item_category5: 'Short sleeve',
            item_list_id: 'related_products',
            item_list_name: 'Related Products',
            item_variant: 'green',
            location_id: 'L_12345',
            price: 14.99,
            quantity: 1,
          },
        ],
      },
    };
    if (isPlatformBrowser(this.platformId) && this.analytics) {
      logEvent(
        this.analytics,
        this.googleAnalytics.name,
        this.googleAnalytics.params
      );
    }
  }

  private addItemCart(item: any, distributor: string) {
    this.itemsCart.push({
      item_id: item.sku,
      item_name: item.descricao_produto,
      affiliation: distributor,
      coupon: '',
      currency: 'BRL',
      discount: 0,
      index: 1,
      item_brand: item.descricao_marca,
      item_category: 'TIPO ONDE O PRODUTO FOI ADICIONADO',
      item_category2: 'GRUPO DE ONDE O PRODUTO FOI ADICIONADO',
      item_category3: 'SUBGRUPO DE ONDE O PRODUTO FOI ADICIONADO',
      item_list_name: item.tipo_produto,
      item_variant: item.variante,
      price: item.preco_aplicado,
      quantity: item.quantidade,
    });
  }
}
