<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="">Detalhes do Endereço</h5>
    </div>
    <div class="modal-body">
      <form [formGroup]="cepForm">
        <div class="uk-grid-small" uk-grid>
          <div class="campo-responsavel uk-width-1-1">
            <label for="nome_responsavel">Pessoa de contato</label><br />

            <input
              type="text"
              class="pesquisa-rua uk-width-1-1"
              id="nome_responsavel"
              formControlName="nome_responsavel"
              [maxlength]="100"
            />
          </div>
          <div class="campo-do-cep uk-width-1-3">
            <label for="cep">CEP:</label><br />
            <input
              class="pesquisa-cep uk-width-1-1"
              type="text"
              id="cep"
              formControlName="cep"
              (blur)="consultaCep()"
              mask="00000-000"
            />
          </div>
          <div class="campo-rua uk-width-2-3">
            <label for="logradouro">RUA</label><br />
            <input
              type="text"
              class="pesquisa-rua uk-width-1-1"
              id="logradouro"
              formControlName="logradouro"
              [maxlength]="255"
            />
          </div>
          <div *ngIf="cep && !cep.valid" class="uk-width-1-1 my-1">
            <small class="alerta">{{ cep.message }}</small>
          </div>
          <div class="campo-bairro uk-width-3-4">
            <label for="bairro">BAIRRO</label><br />
            <input
              type="text"
              class="pesquisa-bairro uk-width-1-1"
              id="bairro"
              formControlName="bairro"
              [maxlength]="255"
            />
          </div>

          <div class="campo-uf uk-width-1-4">
            <label for="uf">UF</label><br />
            <input
              type="text"
              class="pesquisa-uf uk-width-1-1"
              id="uf"
              formControlName="uf"
              [maxlength]="2"
            />
          </div>

          <div class="campo-cidade uk-width-3-4">
            <label for="cidade">CIDADE</label><br />
            <input
              type="text"
              class="Form-control uk-width-1-1"
              id="cidade"
              formControlName="cidade"
            />
          </div>

          <div class="campo-numero uk-width-1-4">
            <label for="numero">NÚMERO</label><br />
            <input
              type="text"
              class="pesquisa-numero uk-width-1-1"
              id="numero"
              formControlName="numero"
              mask="0||00||000||0000||00000||000000"
              [maxlength]="255"
            />
          </div>

          <div class="campo-complemento uk-width-1-2">
            <label for="complemento">COMPLEMENTO</label><br />
            <input
              type="text"
              class="pesquisa-complemento uk-width-1-1"
              id="complemento"
              formControlName="complemento"
              [maxlength]="255"
            />
          </div>

          <div class="campo-telefone uk-width-1-2">
            <label for="phone">TELEFONE</label><br />
            <input
              type="text"
              class="pesquisa-complemento uk-width-1-1"
              id="phone"
              formControlName="phone"
              mask="(00) 00000-0000||(00) 0000-0000"
            />
          </div>
          <div class="col-md-12 form-group">
            <label class="form-label m-2">Selecione o tipo de endereço:</label>
            <div class="container">
              <div class="button-wrap">
                <label
                  [ngClass]="
                    selectedHome ? 'selected-location' : 'button-label'
                  "
                  for="casa"
                  (click)="selected('home')"
                >
                  <h1><span uk-icon="home"></span>Casa</h1>
                </label>
                <label
                  [ngClass]="selectedJob ? 'selected-location' : 'button-label'"
                  for="trabalho"
                  (click)="selected('job')"
                >
                  <h1>
                    <h1><span uk-icon="laptop"></span>Trabalho</h1>
                  </h1>
                </label>
                <label
                  [ngClass]="
                    selectedOther ? 'selected-location' : 'button-label'
                  "
                  for="outros"
                  (click)="selected('other')"
                >
                  <h1><span uk-icon="location"></span>Outros</h1>
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer p-0 border-0">
      <div class="col-6 m-0 p-0">
        <button
          type="button"
          class="btn border-top btn-lg btn-block uk-modal-close"
          type="button"
        >
          Fechar
        </button>
      </div>
      <div class="col-6 m-0 p-0">
        <button
          [ngClass]="!cepForm.valid ? 'uk-disabled' : 'btn-success'"
          (click)="enviarForm()"
          class="btn btn-lg btn-block uk-modal-close"
        >
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>
