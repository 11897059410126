<nav class="uk-navbar-container nav-container" uk-navbar>
  <div
    class=""
    style="margin: 0 auto"
    [ngStyle]="{ width: buttons.length != 0 ? '90%' : '72%' }"
  >
    <div class="" style="width: 100%; display: flex; gap: 2rem">
      <div [ngStyle]="{ width: buttons.length != 0 ? '70%' : '80%' }">
        <ul
          class="uk-navbar-nav uk-flex-middle uk-flex-between"
          style="overflow: inherit"
          #scrollingMenu
        >
          <!-- <li [routerLink]="'/'">
            <div
              class="uk-padding-small uk-text-center uk-border-circle home-icon"
              uk-tooltip="title: Página Inicial; pos: bottom"
            >
              <span uk-icon="icon: home"></span>
            </div>
          </li> -->
          <ng-container *ngIf="departments">
            <li *ngFor="let d of departments">
              <a
                class="descricao"
                style="font-size: 0.85rem; min-height: 50px"
                >{{ capitalizeSentence(d.description) }}</a
              >
              <div class="uk-dropdown" uk-dropdown>
                <div
                  class="uk-drop-grid uk-grid uk-grid-small uk-flex-center"
                  style="margin: auto; min-width: 500px"
                >
                  <div
                    class="uk-width-4-5 dep-column uk-position-relative"
                    style="display: flex; align-items: center"
                  >
                    <ul
                      class="uk-nav uk-navbar-dropdown-nav"
                      style="width: 90%"
                    >
                      <li *ngFor="let s of d.sub_groups">
                        <a
                          (click)="closeMenuUl($event)"
                          routerLink="/produtos"
                          [queryParams]="{
                            cod_grupo: d.id,
                            cod_subgrupo: s.id
                          }"
                        >
                          {{ s.description }}
                        </a>
                      </li>
                    </ul>
                    <!--  ===============================================================  -->
                  </div>
                  <div class="uk-width-1-5 text-center">
                    <img
                      appImagemAuth
                      class="segmento-img"
                      [src]="d.cover"
                      alt="Exalla"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
      <div
        class="uk-flex uk-flex-center"
        [ngStyle]="{ width: buttons.length != 0 ? '30%' : '20%' }"
      >
        <div
          class="brands"
          [ngStyle]="{ width: buttons.length != 0 ? setButtonSize() : '100%' }"
        >
          <a
            class="descricao marcas uk-flex uk-flex-center uk-flex-middle uk-width-1-1"
            style="height: 100%"
          >
            <div>Marcas</div>
          </a>
          <div class="uk-navbar-dropdown">
            <div class="brands-wrapper">
              <div class="alphabet">
                <ng-template ngFor let-letter [ngForOf]="alphabet">
                  <a
                    *ngIf="
                      brands && brands[letter] && brands[letter].length > 0
                    "
                    (click)="scrollToLetter(letter)"
                  >
                    {{ letter }}
                  </a>
                </ng-template>
              </div>
              <div class="list" id="brand-list">
                <ng-template ngFor let-letter [ngForOf]="alphabet">
                  <ul
                    *ngIf="
                      brands && brands[letter] && brands[letter].length > 0
                    "
                  >
                    <div class="descricao" [id]="letter">{{ letter }}</div>
                    <li *ngFor="let b of brands[letter]; index as i">
                      <a
                        class="descricao"
                        (click)="closeMenu($event)"
                        routerLink="/produtos"
                        [queryParams]="{
                          descricao_marca: b.descricao,
                          cod_marca: b.cod_marca
                        }"
                      >
                        {{ b.descricao }}
                      </a>
                    </li>
                  </ul>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="gifts"
          [ngStyle]="{ width: buttons.length != 0 ? setButtonSize() : '100%' }"
          routerLink="/combos"
        >
          <a
            class="descricao gifts uk-flex uk-flex-center uk-flex-middle uk-width-1-1"
            style="height: 100%"
          >
            <div>Combos</div>
          </a>
        </div> -->

        <!-- <div
          [routerLink]="['/produtos']"
          [queryParams]="{ oferta: 'true' }"
          class="ofertas uk-flex uk-flex-middle uk-flex-center"
          style="width: 50%; height: 100%; cursor: pointer"
        >
          <a class="descricao" style="color: #ffffff"> Ofertas Imperdíveis </a>
        </div> -->
        <div
          *ngFor="let button of buttons"
          class="button ofertas uk-flex uk-flex-middle uk-flex-center"
          style="width: 33%; height: 100%; cursor: pointer"
          [ngStyle]="{
            display: buttons.length != 0 ? 'flex' : 'none',
            'background-color': button.cor_background,
            width: setButtonSize()
          }"
          [routerLink]="['/produtos']"
          [queryParams]="{ lista: button.lista }"
        >
          <p style="margin: 0" *ngIf="!button.nome">Ofertas</p>
          <p
            style="margin: 0"
            *ngIf="button.nome"
            [ngStyle]="{
              color: button.cor_texto,
              'font-weight': button.peso_fonte * 10,
              'font-size': button.tamanho_fonte + 'px'
            }"
          >
            {{ button.nome }}
          </p>
        </div>
      </div>
    </div>
  </div>
</nav>
