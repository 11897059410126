<div>
  <div
    class="uk-grid uk-child-width-1-2 uk-child-width-1-5@m uk-flex-center"
    uk-grid
  >
    <div *ngFor="let p of products" class="web">
      <app-produto (click)="getProduct(p)" [produto]="p"></app-produto>
    </div>
  </div>
  <div class="mob">
    <div
      class="uk-position-relative uk-visible-toggle uk-light uk-margin-top uk-margin-bottom"
      tabindex="-1"
      id="images"
      uk-slider="autoplay: true"
    >
      <ul class="uk-slider-items uk-child-width-1-2 uk-grid">
        <li *ngFor="let p of products">
          <div class="uk-panel">
            <app-produto [produto]="p" [foraPadrao]="false"></app-produto>
          </div>
        </li>
      </ul>

      <a
        class="uk-position-center-left uk-position-small"
        href
        uk-slidenav-previous
        uk-slider-item="previous"
        style="color: #ff6060"
      ></a>
      <a
        class="uk-position-center-right uk-position-small"
        href
        uk-slidenav-next
        uk-slider-item="next"
        style="color: #ff6060"
      ></a>
    </div>
  </div>
</div>
