import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Departamento } from 'src/app/model/departamento';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IHomeBrand } from 'src/app/model/home.structure.model';
import { ListaProduto } from 'src/app/model/listaproduto.model';
import {
  FormsModule,
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
} from '@angular/forms';

@Component({
  selector: 'app-filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss'],
})
export class FiltroComponent implements OnInit {
  @Output() elementsFiltrado = new EventEmitter<any>();
  showOffer: boolean = true;

  formFiltro = new FormGroup({
    cod_grupo: new FormControl<string[] | null>([]),
    cod_marca: new FormControl<string[] | null>([]),
    desconto: new FormControl(false),
    sort: new FormControl(''),
  });

  filters = [
    {
      name: 'Menor Preço',
      value: 'preco_venda ASC',
    },
    {
      name: 'Maior Preço',
      value: 'preco_venda DESC',
    },
    {
      name: 'Nome A - Z',
      value: 'descricao ASC',
    },
    {
      name: 'Nome Z - A',
      value: 'descricao DESC',
    },
  ];

  params?: any;
  _departments: Departamento[] = [];
  brands: IHomeBrand[] = [];
  products: ListaProduto[] = [];
  currentRoute!: string;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params: any) => {
      let teste = params;
      if (this.router.url.includes('ofertas') || teste.favorito) {
        if (this.router.url.includes('ofertas')) {
          this.showOffer = false;
        }
      }
      this.currentRoute = this.router.url.split('/')[1];
    });
  }

  ngOnInit(): void {
    this.httpClient.get(`${environment.api}/department`).subscribe({
      next: (value: any) => {
        this._departments = value;
      },
    });

    this.route.queryParams.subscribe((params: any) => {
      this.params = params;

      const newValue = {
        cod_grupo: params.cod_grupo ? params.cod_grupo.split(',') : [],
        cod_marca: params.cod_marca ? params.cod_marca.split(',') : [],
        sort: params.sort,
        favorito: params.favorito,
        desconto: params.desconto === 'true' ? true : false,
      };

      if (params.cod_subgrupo) {
        newValue['cod_grupo'] = params.cod_subgrupo.split(',');
      }

      this.formFiltro.patchValue(newValue);
      if (this.products.length === 0) {
        this.handleSearchBrand();
      }
    });
  }

  handleSearchBrand() {
    const controls = this.formFiltro
      ? this.formFiltro.controls['cod_grupo'].value
      : '';
    this.brands = [];
    this.httpClient
      .post(`${environment.api}/home/brand`, {
        search: this.params.q ?? '',
        cod_grupo: this.params.cod_grupo ?? controls,
        cod_subgrupo: this.params.cod_subgrupo,
        path: this.currentRoute ?? '',
      })
      .subscribe({
        next: (value: any) => {
          this.brands = value;
        },
      });
  }

  onAplicar() {
    let queryParams = {};
    const urlAtual = window.location.href;
    this.elementsFiltrado.emit(this.formFiltro.value);
    queryParams = {};
    if (this.params.pesquisa) {
      let pesquisa = urlAtual.split('pesquisa=')[1].split('&')[0];
      queryParams = {
        pesquisa: pesquisa,
        sort: this.formFiltro.value.sort,
        desconto: !this.showOffer ? true : this.formFiltro.value.desconto,
      } as { [key: string]: string | boolean | undefined };
    } else if (this.params.favorito === 'true') {
      let favorito = urlAtual.split('favorito=')[1].split('&')[0];
      queryParams = {
        favorito: favorito,
        sort: this.formFiltro.value.sort,
        desconto: !this.showOffer ? true : this.formFiltro.value.desconto,
        cod_marca: this.formFiltro.value.cod_marca?.join(','),
      } as { [key: string]: string | boolean | undefined };
    } else if (this.params.oferta === 'true') {
      queryParams = {
        sort: this.formFiltro.value.sort ?? '',
        desconto: true,
        oferta: true,
        cod_marca: this.formFiltro.value.cod_marca?.join(','),
      } as { [key: string]: string | boolean | undefined };
    } else if (this.params.lista) {
      queryParams = {
        sort: this.formFiltro.value.sort ?? '',
        desconto: !this.showOffer ? true : this.formFiltro.value.desconto,
        cod_marca: this.formFiltro.value.cod_marca?.join(','),
        lista: this.params.lista,
      } as { [key: string]: string | boolean | undefined };
    } else {
      queryParams = {
        cod_marca: this.formFiltro.value.cod_marca?.join(','),
        sort: this.formFiltro.value.sort,
        desconto: !this.showOffer ? true : this.formFiltro.value.desconto,
        cod_grupo: this.params.cod_grupo,
        cod_subgrupo: this.params.cod_subgrupo,
        descricao_marca: this.params.descricao_marca,
      } as { [key: string]: string | boolean | undefined };
    }

    // if (this.isDisplayingDepartments) {
    //   queryParams['cod_grupo'] = this.formFiltro.value.cod_grupo![0];
    // } else {
    //   queryParams['cod_subgrupo'] = this.formFiltro.value.cod_grupo![0]
    // }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
    });

    this.handleSearchBrand();
  }

  onLimpar() {
    this.formFiltro.reset();

    this._departments.forEach((departments) => (departments.checked = false));
    this.brands.forEach((IHomeBrand) => (IHomeBrand.checked = false));

    let queryParams: any = {
      cod_grupo: this.params['cod_grupo'],
      cod_subgrupo: this.params['cod_subgrupo'],
      // desconto: "false"
    };
    if (this.params['favorito']) {
      queryParams = {
        favorito: 'true',
      };
    }
    if (this.params['oferta']) {
      queryParams = {
        oferta: 'true',
        desconto: 'true',
      };
    }
    if (this.params['lista']) {
      queryParams = {
        lista: this.params.lista,
        acao: this.params.acao,
      };
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
    });
  }

  changeGrupo(e: Event, departamento: Departamento) {
    const selectedGroup = this.formFiltro.controls['cod_grupo'];
    const target = e.target as HTMLInputElement;

    if (target.checked) {
      departamento.checked = true;
      selectedGroup.value?.push(target.value);
      return;
    }

    const index = selectedGroup.value?.findIndex((id) => id === target.value);
    selectedGroup.value?.splice(index as number, 1);
    departamento.checked = false;
  }

  changeMarca(e: Event, brand: IHomeBrand) {
    const selectedMarca = this.formFiltro.controls['cod_marca'];
    const target = e.target as HTMLInputElement;

    if (target.checked) {
      brand.checked = true;
      selectedMarca.value?.push(target.value);
      return;
    }

    const index = selectedMarca.value?.findIndex((id) => id === target.value);
    selectedMarca.value?.splice(index as number, 1);
    brand.checked = false;
  }

  changeOrdenar() {
    this.onAplicar();
  }

  get departments() {
    if (!this._departments) {
      return [];
    }

    const departmentIds = this.params?.cod_grupo?.split(',').filter(Boolean);
    const subgroupsIds = this.params?.cod_subgrupo?.split(',').filter(Boolean);

    if (departmentIds && departmentIds.length === 1) {
      return this._departments.find(
        (departments) => departments.id === departmentIds[0]
      )?.sub_groups;
    }

    if (subgroupsIds && subgroupsIds.length > 0) {
      return this._departments.find((departments) => {
        return Boolean(
          departments.sub_groups?.find(
            (subgroup) => subgroup.id === subgroupsIds[0]
          )
        );
      })?.sub_groups;
    }

    return this._departments;
  }

  get isDisplayingDepartments() {
    const departmentIds = this.params?.cod_grupo?.split(',').filter(Boolean);
    const subgroupsIds = this.params?.cod_subgrupo?.split(',').filter(Boolean);

    if (
      (departmentIds && departmentIds.length === 1) ||
      (subgroupsIds && subgroupsIds.length > 0)
    ) {
      return false;
    }

    return true;
  }
}
