<form>
  <div #modalpix>
    <div class="uk-modal-body">
      <div class="qrcodeImage" *ngIf="copia_e_cola && !pix_pago">
        <qrcode
          [qrdata]="copia_e_cola"
          [allowEmptyString]="true"
          [ariaLabel]="'QR Code pagamento PIX'"
          [cssClass]="'div-pix'"
          [colorDark]="'#000000ff'"
          [colorLight]="'#ffffffff'"
          [elementType]="'canvas'"
          [errorCorrectionLevel]="'M'"
          [imageHeight]="75"
          [imageWidth]="75"
          [margin]="4"
          [scale]="1"
          [title]="'QR Code Pagamento PIX'"
          [width]="250"
        ></qrcode>

        <span #barcode id="barcode" class="uk-hidden">{{ copia_e_cola }}</span>

        <div class="uk-margin-small-top uk-text-center">
          <button
            class="uk-button uk-button-secondary uk-button-small"
            (click)="copyBarcode()"
          >
            <span uk-icon="icon: copy"> </span> Copiar Pix
          </button>
        </div>

        <div class="uk-text-center uk-margin-small">
          <span>
            {{ copia_e_cola }}
          </span>
        </div>
        <div class="uk-text-center uk-margin-small">
          <span>
            Pague através do app do seu banco escaneando o QR Code ou copiando o
            código acima.
          </span>
        </div>
      </div>

      <div class="pix_pago" *ngIf="copia_e_cola && pix_pago">
        <div
          class="uk-flex uk-flex-column uk-flex-center uk-align-center uk-flex-middle uk-text-center"
        >
          <img
            src="../../../../assets/imagens/pix_pago/payment (1).png"
            class="uk-margin-auto uk-text-center"
            width="100"
            height="100"
          />
          <div class="uk-margin-small">
            <span class="uk-text-top uk-text-success uk-text-large"
              >Pix Pago com Sucesso.</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="uk-modal-footer uk-text-center uk-background-default">
      <div (click)="closeModal()" class="uk-button uk-button-primary">
        Pagar Depois
      </div>
    </div>
  </div>
</form>
