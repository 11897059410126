import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  BehaviorSubject,
  catchError,
  Observable,
  Subject,
  take,
  timeout,
} from 'rxjs';
import { iCartDefaultResponse } from 'src/app/views/cart/interfaces/cart.interface';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class CarrinhoService {
  public carrinho!: any;
  carrinhoSubject = new BehaviorSubject<any>([]);
  favoritos: any[] = [];
  public endereco: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private errorHandler: ErrorHandlerService
  ) {}

  onCartChanged() {
    return this.carrinhoSubject.asObservable();
  }

  createProduct(cod_produto: string, quantity: number, operation: '+' | '-') {
    return this.httpClient
      .post(`${environment.api}/cart/control`, {
        cod_produto: cod_produto,
        cod_carrinho: localStorage.getItem('cod_carrinho') ?? undefined,
        quantity: quantity,
        operation,
      })
      .pipe(catchError(this.errorHandler.handleError));
  }
  getApiSeller(vendedor: any): Observable<any> {
    return this.httpClient.get(
      `${environment.api}/seller/${vendedor.cod_vendedor}`
    );
  }

  // removeProduct(cod_produto: string) {
  //   this.httpClient
  //     .post(`${environment.api}/cart/remove/`, {
  //       cod_carrinho: localStorage.getItem('cod_carrinho'),
  //       cod_produto,
  //     })
  //     .subscribe({
  //       next: () => {
  //         let items: any = {};
  //         items.products = [];
  //         this.carrinhoSubject.next(items);
  //         this.carrinho = [];
  //         this.carrinhoSubject.unsubscribe();
  //       },
  //     });
  // }

  removeProduct(cod_produto: string) {
    return this.httpClient.post<any>(`${environment.api}/cart/remove/`, {
      cod_carrinho: localStorage.getItem('cod_carrinho'),
      cod_produto,
    });
  }

  returnCarrinho(cod_cupom?: string, cod_carrinho?: string) {
    let cupom;
    let carrinho;
    if (localStorage.getItem('cod_carrinho')) {
      carrinho = localStorage.getItem('cod_carrinho');
    } else {
      carrinho = '';
    }
    if (cod_cupom) {
      cupom = cod_cupom;
    } else {
      cupom = '';
    }
    return this.httpClient
      .post<iCartDefaultResponse>(`${environment.api}/cart/checkout`, {
        cod_carrinho: carrinho,
        cupom_carrinho: cupom,
      })
      .pipe(
        take(2),
        // timeout(5000),
        catchError(this.errorHandler.handleError)
      );
  }

  setHaverEndereco(endereco: boolean) {
    this.endereco = endereco;
  }
}
