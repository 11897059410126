import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, take, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  iAddCardRequest,
  iEditCardResponse,
  iGetCardsResponse,
  iRemoveCardRequest,
} from '../interfaces/cartao.interface';

@Injectable({
  providedIn: 'root',
})
export class CartoesService {
  listaCartoes = new Subject<any>();

  constructor(private http: HttpClient) {}

  getCartao(): Observable<iGetCardsResponse> {
    return this.http
      .get<iGetCardsResponse>(`${environment.api}/card`)
      .pipe(take(3), timeout(5000));
  }

  addCartao(req: iAddCardRequest): Observable<iEditCardResponse> {
    return this.http
      .post<iEditCardResponse>(`${environment.api}/card`, req)
      .pipe(take(3), timeout(30000));
  }

  deleteCartao(req: iRemoveCardRequest): Observable<iEditCardResponse> {
    return this.http.delete<iEditCardResponse>(`${environment.api}/card`, {
      body: req,
    });
  }

  getEnderecos() {
    return this.http.get(`${environment.api}/account/address`);
  }
}
