<div
  *ngIf="offers.length > 0"
  class="uk-position-relative uk-light mb-3"
  uk-slideshow="animation: push; autoplay: true; ratio: 7:3; max-height: 850"
>
  <div class="uk-slideshow-items">
    <div *ngFor="let o of offers">
      <img
        appImagemAuth
        class="slide"
        (click)="clickImagem(o.rota)"
        src="{{ o.imagem }}"
        [alt]="o.descricao"
        uk-cover
      />
    </div>
  </div>

  <a
    class="uk-position-center-left uk-position-small uk-hidden-hover"
    href="#"
    uk-slidenav-previous
    uk-slideshow-item="previous"
  ></a>
  <a
    class="uk-position-center-right uk-position-small uk-hidden-hover"
    href="#"
    uk-slidenav-next
    uk-slideshow-item="next"
  ></a>
  <div class="navegacao uk-position-bottom-center uk-position-small">
    <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
  </div>
</div>
