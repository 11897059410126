import { Component, OnInit, Input } from '@angular/core';
import { IHomeStructure } from '../../../model/home.structure.model';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  @Input('data')
  data!: IHomeStructure;
  target: string = '_self';

  constructor() {}

  ngOnInit(): void {}

  urlToRedirect() {
    let url;
    if (this.data.rota.includes('redirect')) {
      url = this.data.rota.toString().split('=')[1];
      this.target = '_blank';
      return `https://${url}`;
    } else {
      this.target = '_self';
      url = this.data.rota.toString();
      return `https://${url}`;
    }
  }
}
