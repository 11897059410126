import { Component, OnInit, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SocialLoginService } from '../../services/social-login.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Pagamento } from '../../../model/pagamento';
@Component({
  selector: 'app-cadastro-max-pago',
  templateUrl: './cadastro-max-pago.component.html',
  styleUrls: ['./cadastro-max-pago.component.scss']
})
export class CadastroMaxPagoComponent implements OnInit {
  @Output() Pagamento: any;

  formCadastro!: FormGroup

  dataLimite: string = new Date().toISOString().split('T')[0];


  constructor(
    private SocialLoginService: SocialLoginService,
    private router: Router,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {

    this.formCadastro = new FormGroup({
      name: new FormControl(''),
      cpf_cnpj: new FormControl(''),
      phone: new FormControl(''),
      email: new FormControl(''),
      password: new FormControl(''),
      confirmar: new FormControl(''),
      genero: new FormControl(''),
      data: new FormControl('')

    })

  }
  maxPago() {
    this.formCadastro = new FormGroup({
      name: new FormControl(''),
      cpf_cnpj: new FormControl(''),
      phone: new FormControl(''),
      email: new FormControl(''),
      password: new FormControl(''),
      confirmar: new FormControl(''),
      genero: new FormControl(''),
      data: new FormControl('')

    })
  }

}
