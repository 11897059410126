import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
  ReflectiveInjector,
  ViewContainerRef,
} from '@angular/core';
import UIkit from 'uikit';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private viewContainerRef!: ViewContainerRef;
  private componentRef!: ComponentRef<any>;
  title!: string;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
  ) {}

  // PASSAR HTML DO MODAL
  setViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }

  // PASSAR O COMPONENTE A SER MOSTRADO NO MODAL
  loadComponent(component: any, config: any = {}) {
    this.destroyComponent();
    this.title = config.data.title;
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(component);
    const providers = Object.keys(config).map((key) => ({
      provide: 'data',
      useValue: config[key],
    }));
    const childInjector = Injector.create({ providers, parent: this.injector });
    this.componentRef = this.viewContainerRef.createComponent(
      componentFactory,
      0,
      childInjector
    );
    UIkit.modal(document.getElementById('genericModal')!).show();
  }

  // FECHAR O MODAL E DESTRUIR O COMPONENTE
  destroyComponent() {
    if (this.componentRef) {
      UIkit.modal(document.getElementById('genericModal')!).hide();
      this.componentRef.destroy();
    }
  }
}
