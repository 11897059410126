<form class="formulario" [formGroup]="formCadastro" (submit)="maxPago()">
  <div>
    <div class="form-group">
      <label for="number">CPF</label>
      <input formControlName="cpf_cnpj" placeholder="CPF" type="text" class="form-control" id="number"
        mask="000.000.000-00" />
      <small class="alerta" *ngIf="
          this.formCadastro.controls['cpf_cnpj'].value &&
          this.formCadastro.controls['cpf_cnpj'].errors
        ">Insira CPF correto</small>
    </div>
    <div class="form-group">
      <label for="telefone">Telefone</label>
      <input formControlName="phone" placeholder="Telefone" type="text" class="form-control" id="telefone" prefix="+55"
        mask="(00) 00000-0000" />
    </div>

    <div class="form-group">
      <label for="data">Data de nascimento</label>
      <input formControlName="data" type="date" class="form-control" id="data" min="1899-01-01" [max]="dataLimite" />
    </div>
    <div class="form-group">
      <label for="email">Email</label>
      <input formControlName="email" placeholder="Email" type="email" class="form-control" id="email"
        aria-describedby="emailHelp" />
      <small class="alerta" *ngIf="
          this.formCadastro.controls['email'].value &&
          this.formCadastro.controls['email'].status === 'INVALID'
        ">Insira um Email válido</small>
    </div>

    <div class="form-group">
      <label for="gênero">Gênero</label>
      <label><input class="uk-radio" type="radio" value="M" name="genero" formControlName="genero" checked />
        Masculino</label>
      <label><input class="uk-radio" type="radio" value="F" name="genero" formControlName="genero" />
        Feminino</label>
    </div>
  </div>
  <app-cep></app-cep>
</form>