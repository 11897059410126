import { AfterViewInit, Component, ComponentRef, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SlideModalService } from '../../services/slide-modal.service';
import { SocialLoginService } from '../../services/social-login.service';
import { SlideModalComponent } from '../slide-modal/slide-modal.component';
import { CarrinhoService } from '../../services/carrinho.service';

@Component({
  selector: 'app-bottom-menu',
  templateUrl: './bottom-menu.component.html',
  styleUrls: ['./bottom-menu.component.scss'],
})
export class BottomMenuComponent implements OnInit {

  // @HostListener('window:click', ['$event']) onClick(event: any) {
  //   event.stopPropagation();
  // }

  buttonElements!: ElementRef;
  globalInstance!: any;
  openModal: boolean = false;
  modal!: ComponentRef<SlideModalComponent>
  @ViewChild('slideModal', {read: ElementRef}) slideModal!: ElementRef;
  qtdCarrinho: number = 0;


  constructor(
    public loginSocial: SocialLoginService,
    private carrinhoService: CarrinhoService,
    public socialLoginService: SocialLoginService
  ) {
  
  }
  
   
  

  ngOnInit(): void {
    this.carrinhoService.carrinhoSubject 
    .subscribe({
      next: items => {
        if (items) {
          this.qtdCarrinho = items.products ? items.products.length : 0
        } else {
          this.qtdCarrinho = 0
        }
      }
    })
  }

    showModal() {
      if (this.slideModal.nativeElement.children[0].classList.contains('to-top')) {
        this.slideModal.nativeElement.children[0].classList.remove('to-top')
      } else {
        this.slideModal.nativeElement.children[0].classList.add('to-top')
      }
    }

    hideModal(e: boolean) {
      this.slideModal.nativeElement.children[0].classList.remove('to-top')
    }
}
