import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CepService {

  constructor(
    private httpClient: HttpClient,

  ) { }

  buscarCep(cep:string){
    // return of({
    //   valid: false,
    //   cep: '60441035',
    //   logradouro: 'rua dfsfsd',
    //   bairro: 'pana',
    //   cidade: 'Sao Paulo',
    //   uf: 'ce',
    //   complemento: 'complemento'
    // })
    return this.httpClient.post(`${environment.api}/misc/cep`,{cep})
  }
}
