import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appImagemAuth]',
})
export class ImagemAuthDirective implements OnInit {
  @HostBinding('src') srcValue!: any;

  constructor(
    private elementRef: ElementRef,
    private http: HttpClient,
    handler: HttpBackend
  ) {
    this.http = new HttpClient(handler);
  }

  ngOnInit(): void {
    const _headers = new HttpHeaders({
      Authorization: 'Basic cG9ydGFsY2xpZW50ZTpwMHJ0Y2wxMjAxOA==',
      'Content-Type': 'text/html; charset=UTF-8',
    });
    const src: string = this.elementRef.nativeElement.src;
    this.http
      .get<any>(src, {
        headers: _headers,
        responseType: 'blob' as any,
        observe: 'response',
      })
      .subscribe({
        next: (response) => {
          if (response.ok === true && response.status === 200) {
            this.blobToBase64(response.body)
              .then((base64String) => {
                this.srcValue = base64String;
              })
              .catch((error) => {
                this.srcValue = 'assets/imagens/produto-sem-foto-30.png';
              });
          } else {
            this.srcValue = 'assets/imagens/produto-sem-foto-30.png';
          }
        },
        error: (err) => {
          if (err.status === 200 && err.error.text.includes('PNG')) {
            this.srcValue = err.url;
          } else {
            this.srcValue = 'assets/imagens/produto-sem-foto-30.png';
          }
        },
      });
  }

  blobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  }
}
