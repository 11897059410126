<div class="uk-width-medium uk-margin-medium-right d-none d-md-block">
  <div class="osahan-account bg-white rounded shadow-sm overflow-hidden" style="text-align: center">
    <ng-container *ngIf="this.socialLoginService.accountData | async">
      <div *ngIf="this.socialLoginService.accountData; then logged else notLogged">

      </div>
      <ng-template #logged class="p-4 profile text-center border-bottom">
        <img src="assets/icons/usuarios.png" style="width: 150px; height: auto;" class="img-fluid rounded-pill" />
        <h6 class="font-weight-bold m-0 mt-2">
          {{ this.socialLoginService.accountData.value.name }}
        </h6>
        <p class="small text-muted m-0">
          {{ this.socialLoginService.accountData.value.email }}
        </p>
      </ng-template>
      <ng-template #notLogged style="width: 150px; height: auto;" class="p-4 profile text-center border-bottom">
        <img src="assets/icons/usuarios.png" class="img-fluid rounded-pill" />
        <h6 class="font-weight-bold m-0 mt-2">
          Nome
        </h6>
        <p class="small text-muted m-0">
          Email
        </p>
      </ng-template>
    </ng-container>

    <div class="account-sections">
      <ul class="list-group">
        <li [routerLink]="['/minha-conta']" class="border-bottom bg-white d-flex align-items-center p-3">
          <i class="icofont-user osahan-icofont bg-dark"></i>Minha Conta
          <span class="badge p-1 badge-pill ml-auto"><i class="icofont-simple-right"></i></span>
        </li>

        <li [routerLink]="['/my-address']" class="border-bottom bg-white d-flex align-items-center p-3">
          <i class="icofont-address-book osahan-icofont bg-dark"></i>Meus
          Endereços
          <span class="badge p-1 badge-pill ml-auto"><i class="icofont-simple-right"></i></span>
        </li>

        <li [routerLink]="['/adicionar-cartao']" class="border-bottom bg-white d-flex align-items-center p-3">
          <i class="icofont-credit-card osahan-icofont bg-dark"></i>Meus Cartões
          <span class="badge p-1 badge-pill ml-auto"><i class="icofont-simple-right"></i></span>
        </li>
        <!-- <li
          [routerLink]="['/help-support']"
          class="border-bottom bg-white d-flex align-items-center p-3"
        >
          <i class="icofont-phone osahan-icofont bg-dark"></i>Suporte
          <span class="badge p-1 badge-pill ml-auto"
            ><i class="icofont-simple-right"></i
          ></span>
        </li> -->
        <li (click)="logOut()" class="border-bottom bg-white d-flex align-items-center p-3">
          <i class="icofont-lock osahan-icofont bg-dark"></i> Sair
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="uk-grid uk-margin-auto tabs-user d-md-none" style="font-size: .7rem; width: 100%" uk-grid>
  <div [routerLink]="['/minha-conta']" routerLinkActive="active-menu"
    class="border-bottom bg-white d-flex flex-column align-items-center p-3">
    <i class="icofont-user osahan-icofont bg-dark"></i>Minha Conta
  </div>

  <div [routerLink]="['/my-address']" routerLinkActive="active-menu"
    class="border-bottom bg-white d-flex flex-column align-items-center p-3">
    <i class="icofont-address-book osahan-icofont bg-dark"></i>Meus
    Endereços
  </div>

  <div [routerLink]="['/adicionar-cartao']" routerLinkActive="active-menu"
    class="border-bottom bg-white d-flex flex-column align-items-center p-3">
    <i class="icofont-credit-card osahan-icofont bg-dark"></i>Meus Cartões
  </div>
  <!-- <div
    [routerLink]="['/help-support']"
    routerLinkActive="active-menu"
    class="border-bottom bg-white d-flex flex-column align-items-center p-3"
  >
    <i class="icofont-phone osahan-icofont bg-dark"></i>Suporte
  </div> -->
</div>