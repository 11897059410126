import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Departamento } from 'src/app/model/departamento';
import { environment } from 'src/environments/environment';
import { SlideModalService } from '../../services/slide-modal.service';

@Component({
  selector: 'app-slide-modal',
  templateUrl: './slide-modal.component.html',
  styleUrls: ['./slide-modal.component.scss']
})
export class SlideModalComponent implements OnInit {

  departments: Departamento[] = [];

  @Input() open: boolean = false;
  @Output() close = new EventEmitter<boolean>()
  @ViewChild('box') box!: ElementRef

  constructor(
    private httpClient: HttpClient,
    public slideService: SlideModalService
  ) { }

  ngOnInit(): void {
    this.httpClient.get(`${environment.api}/department`)
      .subscribe({
        next: (value: any) => {
          this.departments = value;
        },
      })
  }

  closeEvent() {
    
  }

  fecharModal() {
    this.box.nativeElement.classList.remove('to-top');   
  }

}
