import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { Pagamento } from 'src/app/model/pagamento';
import { PagamentoService } from '../../services/pagamento.service';
import UIkit from 'uikit';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pagamento-pix',
  templateUrl: './pagamento-pix.component.html',
  styleUrls: ['./pagamento-pix.component.scss'],
})
export class PagamentoPixComponent implements OnInit, OnChanges {
  constructor(
    private router: Router,
    public pagamentoService: PagamentoService,
    private spinner: NgxSpinnerService,
  ) {}

  @Input() pix: any;
  @Input() modal!: any;
  @Output() pagarDepois = new EventEmitter<boolean>();
  @ViewChild('barcode') barcodeElement!: ElementRef;
  @ViewChild('modalpix') pixElement!: ElementRef;

  copia_e_cola!: string;
  pix_pago: boolean = false;
  setPix: any;

  ngOnChanges() {
    if (this.pix && this.pix.copia_e_cola) {
      this.spinner.hide();
      this.copia_e_cola = this.pix.copia_e_cola;
      this.setPix = setInterval(() => {
        this.verifyPaymentPix();
      }, 5000);
    }
  }
  ngOnInit(): void {}

  verifyPaymentPix() {
    this.pagamentoService
      .verificarPix({ txid: this.pix.txid, cod_cart: this.pix.cod_carrinho })
      .subscribe({
        next: (response) => {
          if (response.status == true && response.pix_status == 'CONCLUIDA') {
            clearInterval(this.setPix);
            UIkit.notification({
              message: 'Pix recebido com sucesso! Obrigado.',
              timeout: 2000,
              pos: 'bottom-center',
            });
            setTimeout(() => {
              this.closeModal()
              this.router.navigate(['meus-pedidos'])
            }, 2500)
          }
          if (
            response.status == true &&
            (response.pix_status == 'EXPIRADA' ||
              response.pix_status == 'CANCELADA POR ...')
          ) {
            clearInterval(this.setPix);
            UIkit.notification({
              message: 'Falha no processamento do Pix',
              timeout: 2000,
              pos: 'bottom-center',
            });
          }
        },
      });
  }

  closeModal() {
    UIkit.modal(this.modal).hide();
    clearInterval(this.setPix);
    this.pagarDepois.emit(true);
  }

  copyBarcode() {
    let copyText = this.barcodeElement.nativeElement.innerText;
    window.navigator.clipboard
      .writeText(copyText)
      .then(() => {
        UIkit.notification({
          message: 'Pix copiado com sucesso',
          timeout: 2000,
          pos: 'bottom-center',
        });
      })
      .catch((error) => {
        if (error) {
          UIkit.notification({
            message: 'Pix copiado com sucesso',
            timeout: 2000,
            pos: 'bottom-center',
          });
        }
      });
  }
}
