import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { IAccountAdress } from 'src/app/model/account.model';
import { SocialLoginService } from '../../services/social-login.service';
import { CepService } from '../../services/cep.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EnderecoService } from '../../services/endereco.service';
import UIkit from 'uikit';
import { CarrinhoService } from '../../services/carrinho.service';

@Component({
  selector: 'app-meus-enderecos',
  templateUrl: './meus-enderecos.component.html',
  styleUrls: ['./meus-enderecos.component.scss']
})
export class MeusEnderecosComponent implements OnInit {

  @Output() haverEndereco: EventEmitter<any> = new EventEmitter;


  get address(): IAccountAdress[] {
    return this.enderecoService.address;
  }

  set address(address: IAccountAdress[]) {
    this.enderecoService.address = address;
  }

  constructor(

    private spinner: NgxSpinnerService,
    public socialLoginService: SocialLoginService,
    private cepService: CepService,
    private httpClient: HttpClient,
    private enderecoService: EnderecoService,
    private carrinhService: CarrinhoService

  ) { }


  ngOnInit(): void {
    if (localStorage.getItem('@access_token') !== null) {
      this.buscarEnderecos();
    }
  }

  buscarEnderecos() {
    this.httpClient.get(`${environment.api}/account/address`)
      .subscribe({
        next: (value: any) => {
          if (value.status === true) {
            let padrao = value.result.filter((p: any) => p.padrao === true)
            if (padrao.length > 0) {
              this.haverEndereco.emit(true);
            }
            this.enderecoService.address = value.result;
            this.enderecoService.addressSubject$.next(value.result)

          } else {
            UIkit.notification({
              message: value.message,
              timeout: 2000,
              pos: 'bottom-center',
              status: 'warning'
            });
          }
        },

      })
  }
  consultaCep(valor: any, form: any) {
    this.cepService.buscarCep(valor)

      .subscribe((dados) => this.populaForm(dados, form));
  }

  populaForm(dados: any, form: any) {
    form.setValue({
      cep: dados.cep,
      logradouro: dados.logradouro,
      bairro: dados.bairro,
      cidade: dados.localidade,
      uf: dados.uf
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    return filterValue;
  }

  deletar(cod_endereco: number) {
    this.httpClient.delete(`${environment.api}/account/address`, {
      body: {
        cod_endereco,
      }
    }).subscribe({
      next: (value: any) => {
        if (value.status === false) {
          UIkit.notification({
            message: value.message,
            timeout: 2000,
            pos: 'bottom-center',
            status: 'warning'
          });
        } else {
          this.enderecoService.addressSubject$.next(value.result);
          this.enderecoService.address = this.enderecoService.address.filter(value => value.cod_endereco !== cod_endereco);
          this.buscarEnderecos();
        }
      },
      error: err => {
        UIkit.notification({
          message: JSON.stringify(err.error),
          timeout: 2000,
          pos: 'bottom-center',
          status: 'warning'
        });
      }
    })
  }

  salveForm(e: any) {
    this.haverEndereco.emit(true);
    this.carrinhService.setHaverEndereco(true)

    e.cep = e.cep.replace('-', '')

    this.httpClient.post<any>(`${environment.api}/account/address`, e).subscribe({
      next: value => {
        if (value.status === true) {
          this.buscarEnderecos();
        } else {
          UIkit.notification({
            message: value.message,
            timeout: 3000,
            pos: 'bottom-center',
            status: 'warning'
          });
        }
      },
    })

  }

  editForm(e: any) {
    this.httpClient.patch(`${environment.api}/account/address`, e).subscribe({
      next: (value) => {
        if (value) {
          this.buscarEnderecos();
        }
      },
    })
  }

  mudarPadrao(cod_endereco: any) {
    this.httpClient.put(`${environment.api}/account/address`, {
      cod_endereco
    }).subscribe({
      complete: () => {
        this.buscarEnderecos();
      },
    })
  }
}
