import { environment } from './../../../../environments/environment';
import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CepService } from '../../services/cep.service';
import { Router } from '@angular/router';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { IAccountAdress } from 'src/app/model/account.model';
import { outputAst } from '@angular/compiler';

//TODO Corrigir HTML
@Component({
  selector: 'app-cadastro-de-endereco',
  templateUrl: './cadastro-de-endereco.component.html',
  styleUrls: ['./cadastro-de-endereco.component.scss'],
})
export class CadastroDeEnderecoComponent implements OnChanges {
  @Input() changeEdit!: IAccountAdress;
  @Output() salveForm: EventEmitter<any> = new EventEmitter();
  @Output() editForm: EventEmitter<any> = new EventEmitter();

  cep?: any;
  selectedHome: boolean = false;
  selectedJob: boolean = false;
  selectedOther: boolean = false;

  cepForm!: FormGroup;
  constructor(private cepService: CepService) {
    this.cepForm = new FormGroup({
      nome_responsavel: new FormControl('', [Validators.required]),
      cep: new FormControl('', [Validators.required]),
      logradouro: new FormControl('', [Validators.required]),
      bairro: new FormControl('', [Validators.required]),
      cidade: new FormControl('', [Validators.required]),
      uf: new FormControl('', [Validators.required]),
      numero: new FormControl('', [Validators.required]),
      complemento: new FormControl(''),
      phone: new FormControl('', [Validators.required]),
      tipo: new FormControl('', [Validators.required]),
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.changeEdit) {
      this.cepForm.patchValue({
        nome_responsavel: this.changeEdit.nome_responsavel,
        cep: this.changeEdit.cep,
        logradouro: this.changeEdit.rua,
        bairro: this.changeEdit.bairro,
        cidade: this.changeEdit.cidade,
        uf: this.changeEdit.uf,
        phone: this.changeEdit.phone,
        complemento: this.changeEdit.complemento,
        tipo: this.changeEdit.tipo,
        numero: this.changeEdit.numero,
      });
    }
  }

  consultaCep() {
    let valor = this.cepForm.controls['cep'].value;
    this.cepService.buscarCep(valor).subscribe({
      next: (dados: any) => {
        this.cep = dados;
        if (this.cep.valid === true) {
          this.populaForm(dados);
        } else {
          this.cepForm.patchValue({
            logradouro: '',
            bairro: '',
            cidade: '',
            uf: '',
            complemento: '',
            phone: '',
          });
        }
      },
      error: (err) => {
        this.cepForm.patchValue({
          logradouro: '',
          bairro: '',
          cidade: '',
          uf: '',
          complemento: '',
          phone: '',
        });
      },
    });
  }

  populaForm(dados: any) {
    if (dados.valid) {
      this.cepForm.patchValue({
        cep: dados.cep,
        logradouro: dados.logradouro,
        bairro: dados.bairro,
        cidade: dados.localidade,
        uf: dados.uf,
        complemento: dados.complemento,
        phone: dados.phone,
      });
    }
  }

  enviarForm() {
    if (this.changeEdit == undefined) {
      this.salveForm.emit(this.cepForm.getRawValue());
      if (this.cepForm.valid) {
        this.cepForm.reset();
        this.selected('reset');
      }
    } else {
      this.cepForm.value.cod_endereco = this.changeEdit.cod_endereco;
      this.editForm.emit({
        cod_endereco: this.changeEdit.cod_endereco,
        cod_conta: this.changeEdit.cod_conta,
        ...this.cepForm.getRawValue(),
      });
    }
  }

  selected(value: string) {
    switch (value) {
      case 'home':
        this.selectedHome = true;
        this.selectedJob = false;
        this.selectedOther = false;
        this.cepForm.get('tipo')?.setValue('casa');
        break;
      case 'job':
        this.selectedJob = true;
        this.selectedHome = false;
        this.selectedOther = false;
        this.cepForm.get('tipo')?.setValue('trabalho');
        break;
      case 'other':
        this.selectedOther = true;
        this.selectedJob = false;
        this.selectedHome = false;
        this.cepForm.get('tipo')?.setValue('outros');
        break;
      case 'reset':
        this.selectedOther = false;
        this.selectedJob = false;
        this.selectedHome = false;
        break;
    }
  }
}
