export const marcas = [
  'alyne.png',
  'amend.png',
  'bio_extratus.png',
  'brae.png',
  'bruna_tavares.png',
  'cadiveu.png',
  'corpo_dourado.png',
  'dailus.png',
  'depimiel.png',
  'eico.png',
  'gama.png',
  'hanova.png',
  'haskell.jpg',
  'inoar.png',
  'labotrat.png',
  'lizz.png',
  'lola.jpg',
  'loreal.png',
  'marco_boni.jpg',
  'mundial.png',
  'ricca.png',
  'ruby_rose.png',
  'salon_line.png',
];
