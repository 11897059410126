import { environment } from './../../../../environments/environment';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Departamento } from '../../../model/departamento';
import { Router } from '@angular/router';
import { SocialLoginService } from '../../services/social-login.service';
import { Brand } from 'src/types';
import UIkit from 'uikit';
import { Button } from './models/button.model';

type AlfabeticBrands = {
  [key: string]: Brand[];
};

@Component({
  selector: 'app-departamento',
  templateUrl: './departamento.component.html',
  styleUrls: ['./departamento.component.scss'],
})
export class DepartamentoComponent implements OnInit {
  @ViewChild('scrollingMenu') scrollingMenu!: ElementRef<HTMLElement>;

  departments!: Departamento[];
  brands!: AlfabeticBrands;
  buttons: Button[] = [];

  alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  constructor(
    private httpClient: HttpClient,
    public socialLoginService: SocialLoginService
  ) {}

  hydrateBrands(brands: Brand[]): AlfabeticBrands {
    const alfabeticBrands: AlfabeticBrands = {};
    brands.forEach((brand) => {
      const firstLetter = brand.descricao[0].toUpperCase();
      if (!alfabeticBrands[firstLetter]) {
        alfabeticBrands[firstLetter] = [];
      }
      alfabeticBrands[firstLetter].push(brand);
    });
    return alfabeticBrands;
  }

  ngOnInit(): void {
    this.httpClient.get(`${environment.api}/department`).subscribe({
      next: (value: any) => {
        this.departments = value;
      },
      error: (error) => {},
    });

    this.httpClient.post(`${environment.api}/home/brand`, {}).subscribe({
      next: (value: any) => {
        this.brands = this.hydrateBrands(value);
      },
    });
    this.getButtons();
  }

  advanceScrollingMenu() {
    this.scrollingMenu.nativeElement.scrollLeft += 200;
  }

  retreatScrollingMenu() {
    this.scrollingMenu.nativeElement.scrollLeft -= 200;
  }

  getLetterByIndex(index: number) {
    return Object.keys(this.brands)[index];
  }

  getButtons() {
    this.httpClient.get(`${environment.api}/home/button`).subscribe({
      next: (res: any) => {
        this.buttons = [];
        if (res.result.length > 0) {
          for (let i = 0; i < 2; i++) {
            if (res.result[i] != undefined) {
              this.buttons.push(res.result[i]);
            }
          }
        }

        // this.buttons = res.result.map((button: Button) => {
        //   return { ...button, peso_fonte: Number(button.peso_fonte) };
        // });
      },
      error: (error) => {},
    });
  }

  scrollToLetter(letter: string) {
    const brandList = document.getElementById('brand-list');
    const element = document.getElementById(letter);

    if (brandList && element) {
      brandList.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth',
      });
    }
  }

  setButtonSize(): string {
    let size;
    if (this.buttons.length > 0) {
      size = 100 / this.buttons.length;
    } else {
      size = 100;
    }
    return `${size}%`;
  }

  // loadProducts() {}

  closeMenu(event: Event) {
    const target = event.target as HTMLElement;
    const parentNav = target.closest('.uk-navbar-dropdown');

    parentNav?.classList.remove('uk-open');
  }
  closeMenuUl(event: Event) {
    const target = event.target as HTMLElement;
    const parentNav = target.closest('.uk-dropdown');

    parentNav?.classList.remove('uk-open');
  }
  closeMenuParentUl(event: Event) {
    const target = event.target as HTMLElement;
    const parentNav = target.parentElement?.closest('.uk-dropdown');

    parentNav?.classList.remove('uk-open');
  }

  capitalizeSentence = (text: string) => {
    return text
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
}
