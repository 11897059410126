<div class="row fixed-bottom bottom-menu d-md-none">
  <div (click)="hideModal(true)"
    class="col-3 p-1 d-flex flex-column justify-content-around align-items-center bt-item py-3" [routerLink]="''">
    <div>
      <i class="icofont-ui-home bt-icon"></i>
    </div>
    <div class="text-center">HOME</div>
  </div>
  <div (click)="showModal()"
    class="col-3 p-1 d-flex flex-column justify-content-around align-items-center bt-item py-3 bt-even">
    <div>
      <i class="icofont-shopping-cart bt-icon"></i>
    </div>
    <div class="text-center">PRODUTOS</div>
  </div>
  <div (click)="hideModal(true)" [routerLink]="loginSocial.isLogged == true ? '/signin' : '/carrinho'"
    class="col-3 p-1 d-flex flex-column justify-content-around align-items-center bt-item py-3 bt-even">
    <div>
      <span class="uk-badge">
        {{ qtdCarrinho }}
      </span>
      <i class="icofont-bag bt-icon"></i>
    </div>
    <div class="text-center">CARRINHO</div>
  </div>
  <div (click)="hideModal(true)" [routerLink]="
      !this.socialLoginService.accountData ? ['/signin'] : ['/produtos']" [queryParams]="{ favorito: 'true' }"
    class="col-3 p-1 d-flex flex-column justify-content-around align-items-center bt-item py-3 bt-even">
    <div>
      <i class="icofont-heart bt-icon"></i>
    </div>
    <div class="text-center">FAVORITOS</div>
  </div>
</div>

<app-slide-modal #slideModal [open]="openModal" (close)="hideModal($event)"></app-slide-modal>