<nav uk-navbar>
  <div class="uk-navbar-left">
    <ul class="uk-navbar-nav">
      <form class="filtro" [formGroup]="formFiltro">
        <li class="lista">
          <a>
            <i class="bx bx-filter-alt bx-flip-horizontal"></i>
            Filtrar Por
            <span uk-navbar-parent-icon></span>
          </a>
          <div class="uk-navbar-dropdown">
            <ul class="uk-nav uk-navbar-dropdown-nav" uk-nav>
              <!-- <li class="uk-parent">
                <a href="#">
                  {{ isDisplayingDepartments ? "Grupo" : "Subgrupo" }}
                </a>
                <ul class="uk-nav-sub">
                  <li *ngFor="let d of departments">
                    <label>
                      <input
                        (change)="changeGrupo($event, d)"
                        [id]="d.id"
                        [value]="d.id"
                        [checked]="
                          formFiltro.get('cod_grupo')?.value?.includes(d.id)
                        "
                        class="uk-checkbox"
                        type="checkbox"
                      />
                      <a class="descricao">{{ d.description }}</a>
                    </label>
                  </li>
                </ul>
              </li> -->
              <li class="uk-parent">
                <a href="#">Marcas <span uk-navbar-parent-icon></span> </a>
                <ul
                  class="uk-nav-sub"
                  style="max-height: 10rem; overflow-y: auto; padding: 0.5rem"
                >
                  <div *ngIf="brands.length === 0" class="spinner">
                    <div uk-spinner="ratio: 1"></div>
                  </div>
                  <li *ngFor="let b of brands">
                    <label>
                      <input
                        (change)="changeMarca($event, b)"
                        [id]="b.cod_marca"
                        [value]="b.cod_marca"
                        [checked]="
                          formFiltro
                            .get('cod_marca')
                            ?.value?.includes(b.cod_marca)
                        "
                        class="uk-checkbox"
                        type="checkbox"
                      />
                      <a class="descricao">{{ b.descricao }}</a>
                    </label>
                  </li>
                </ul>
              </li>
              <!-- <li>
                <div class="desconto uk-flex" *ngIf="showOffer === true">
                  <span class="descontos">Descontos</span>
                  <label class="uk-switch" for="default-1">
                    <input formControlName="desconto" type="checkbox" id="default-1" />
                    <div class="uk-switch-slider"></div>
                  </label>
                </div>
              </li> -->
            </ul>
            <div class="action-buttons">
              <button (click)="onAplicar()" class="action-btn">Aplicar</button>
              <button (click)="onLimpar()" class="action-btn">Limpar</button>
            </div>
          </div>
        </li>
        <li class="ordenar">
          <a>
            <span uk-icon="list"></span>
            Ordenar Por
            <span uk-navbar-parent-icon></span>
          </a>
          <div class="uk-navbar-dropdown">
            <ul class="uk-nav uk-navbar-dropdown-nav">
              <li *ngFor="let s of filters">
                <label>
                  <input
                    (change)="changeOrdenar()"
                    formControlName="sort"
                    [id]="s.value"
                    [value]="s.value"
                    class="uk-radio"
                    type="radio"
                  />
                  <span class="descricao">{{ s.name }}</span>
                </label>
              </li>
            </ul>
          </div>
        </li>
      </form>
    </ul>
  </div>
</nav>
