<a
  uk-tooltip="title: Fale com a Exalla"
  class="whatsapp-float-button uk-padding-small uk-border-circle uk-visible@m"
  href="https://api.whatsapp.com/send?phone=558525252020&text=Ol%C3%A1!%20Vim%20pelo%20site%20da%20Exalla.%20Pode%20tirar%20d%C3%BAvidas%20sobre%20compras%20e%20produtos%2C%20por%20favor%3F"
  target="_blank"
>
  <span uk-icon="icon: whatsapp; ratio: 1.5;" style="color: #ffffff"></span>
</a>
<!-- 
<a uk-tooltip="title: Fale com a Exalla" class="whatsapp-float-button uk-padding-small uk-border-circle uk-hidden@m" href="https://api.whatsapp.com/send?phone=5585991460459&text=Ol%C3%A1!%20Vim%20pelo%20site%20da%20Exalla." target="_blank">
    <span uk-icon="icon: whatsapp; ratio: 1.5;" style="color: #ffffff;"></span>
</a> -->
