import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
  Output,
  Renderer2,
  EventEmitter,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ListaProduto } from '../../../model/listaproduto.model';
import { NavigationEnd, Router } from '@angular/router';
import { SocialLoginService } from '../../services/social-login.service';
import { CarrinhoService } from '../../services/carrinho.service';
import { handle } from '../../utils/cpf-validator-validator';
import UIkit from 'uikit';
import { timeout } from 'rxjs';

@Component({
  selector: 'app-pick-slide',
  templateUrl: './pick-slide.component.html',
  styleUrls: ['./pick-slide.component.scss'],
})
export class PickSlideComponent implements OnInit, AfterViewInit {
  products: ListaProduto[] = [];

  @ViewChild('image')
  image: any;

  @Input()
  path!: string;

  @Input()
  limit!: string;

  @Input()
  id_home!: string;

  @Input()
  rota!: string;

  @Input()
  brand!: string;

  @Output() produto = new EventEmitter<ListaProduto>();

  constructor(
    public renderer: Renderer2,
    private httpClient: HttpClient,
    private router: Router,
    private carrinhoService: CarrinhoService
  ) {}

  ngOnInit(): void {
    this.httpClient
      .post(`${environment.api}/${this.path}`, {
        limit: this.limit,
        id_home: Number(this.id_home),
        descricao_marca: this.brand,
        offers: this.rota === '/ofertas',
      })
      .pipe(timeout(20000))
      .subscribe({
        next: (value: any) => {
          this.products = value.splice(0, 6);
        },
        error: (err) => {},
      });
  }

  ngAfterViewInit(): void {}

  parseValue(value: number): string {
    return Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format(value);
  }

  handleFavorite(p: any) {}

  redirect(cod_produto: string) {
    this.router.navigate(['/product-details', cod_produto]);
    // .then(() => location.reload())
  }
  addCart(cod_produto: string, quantity: number) {
    // this.renderer.addClass(e.target,'uk-animation-fade')
    // e.srcElement.innerHTML = "PRODUTO ADICIONADO"
    // setTimeout(() => {
    //   e.srcElement.innerHTML = "ADICIONAR NO CARRINHO"
    // }, 1500)
    UIkit.notification({
      message: 'Produto adicionado no Carrinho',
      timeout: 900,
      pos: 'bottom-center',
    });

    this.carrinhoService.createProduct(cod_produto, quantity, '+');
  }

  getProduct(p: ListaProduto) {
    this.produto.emit(p);
  }
}
