import { Injectable } from '@angular/core';
import { ListaProduto } from '../model/listaproduto.model';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProdutoService {
  public productList: any = [];

  private readonly API = 'http://localhost:3000/produto';

  produtosPersonalizados = new BehaviorSubject<ListaProduto[]>([]);

  constructor(private http: HttpClient) {}

  public getListaProdutos(): Observable<ListaProduto[]> {
    return this.http.get<ListaProduto[]>(`${environment.api}/product`);
  }

  public getListaPersonalizada(params: any): Observable<ListaProduto[]> {
    let a = [];
    if (params['cod_marca']) {
      a.push(`cod_marca=${params['cod_marca']}`);
    }

    if (params['sort']) {
      a.push(`sort=${params['sort']}`);
    }

    if (params['desconto']) {
      a.push(`desconto=${params['desconto']}`);
    }
    if (params['lista']) {
      a.push(`lista=${params['lista']}`);
    }
    if (params['page']) {
      a.push(`pagina=${params['page']}`);
    }
    if (params['limit']) {
      a.push(`limite=${params['limit']}`);
    }

    const id = typeof params === 'string' ? params : params['lista'];

    return this.http.get<ListaProduto[]>(
      `${environment.api}/lista/produto/${id}?${a.length ? a.join('&') : ''}`
    );
  }

  setProductList(porducts: any) {
    this.productList = porducts;
  }
}
