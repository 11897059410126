<div>
  <app-head></app-head>
</div>
<div id="main-container" [ngClass]="style ? 'uk-margin-auto' : ''">
  <link
    rel="stylesheet"
    href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
  />
  <router-outlet></router-outlet>
</div>
<div class="mg-b">
  <app-footer></app-footer>
</div>
<!-- <app-bottom-menu></app-bottom-menu> -->
<app-whatsapp-float-button></app-whatsapp-float-button>
<div class="cookies-section" *ngIf="cookiesOption == 'false'">
  <div class="cookies-alert">
    <div class="content">
      <div class="logo">
        <img
          src="../assets/imagens/LOGO EXALLA - DEGRADÊ.png"
          width="100"
          alt="logo"
        />
      </div>
      <p>
        Utilizamos cookies para oferecer melhor experiência, melhorar o
        desempenho, analisar como você interage em nosso site e personalizar
        conteúdo. Ao utilizar este site, você concorda com o uso de cookies.
      </p>
    </div>
    <div class="action" (click)="cookies()">
      <span>Entendi</span>
    </div>
  </div>
</div>
