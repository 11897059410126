import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Departamento } from 'src/app/model/departamento';
import { SocialLoginService } from '../../services/social-login.service';
import { environment } from 'src/environments/environment';
import UIkit from 'uikit';
import { Button } from '../departamento/models/button.model';

@Component({
  selector: 'app-menu-responsivo',
  templateUrl: './menu-responsivo.component.html',
  styleUrls: ['./menu-responsivo.component.scss'],
})
export class MenuResponsivoComponent implements OnInit {
  @Input() offCanvas!: Element;
  departments: Departamento[] = [];
  nomeCliente!: string;
  buttons: Button[] = [];

  constructor(
    private httpClient: HttpClient,
    public socialLoginService: SocialLoginService
  ) {}

  ngOnInit(): void {
    this.httpClient.get(`${environment.api}/department`).subscribe({
      next: (value: any) => {
        this.departments = value;
      },
    });
    this.socialLoginService.accountData.subscribe((value) => {
      if (value) {
        this.nomeCliente = value.name;
      }
    });
    this.getButtons();
  }

  close() {
    UIkit.offcanvas(this.offCanvas).hide();
  }

  logOut() {
    this.socialLoginService.logOut();
    location.reload();
  }
  getButtons() {
    this.httpClient.get(`${environment.api}/home/button`).subscribe({
      next: (res: any) => {
        this.buttons = [];
        if (res.result.length > 0) {
          for (let i = 0; i < 2; i++) {
            if (res.result[i] != undefined) {
              this.buttons.push(res.result[i]);
            }
          }
        }

        // this.buttons = res.result.map((button: Button) => {
        //   return { ...button, peso_fonte: Number(button.peso_fonte) };
        // });
      },
      error: (error) => {},
    });
  }
}
