<div *ngIf="produto; then cardLoaded; else cardIsLoading"></div>

<ng-template #cardLoaded>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Carregando....</p>
  </ngx-spinner>
  <div
    class="div-produto uk-padding-small"
    *ngIf="!foraPadrao"
    [routerLink]="['/product-details', produto.cod_produto]"
    [queryParams]="{
      desc: produto.descricao ? produto.descricao.toLowerCase() : '-',
      marca: produto.descricao_marca
        ? produto.descricao_marca.toLowerCase()
        : '-',
      sku: produto.sku
    }"
  >
    <!-- <div
      *ngIf="produto.qt_estoque === 0 || produto.estoque === 0"
      class="teste"
    ></div> -->
    <div *ngIf="produto.desconto" class="desconto">
      <span>{{ produto.desconto }}% OFF</span>
    </div>

    <div
      [ngClass]="{
        'uk-background-default': isFavorite == false,
        favoritar: isFavorite == true
      }"
      class="uk-position-top-right uk-margin-remove uk-border-circle"
      style="padding: 7px; z-index: 10"
    >
      <a
        (click)="favoritar(produto, $event)"
        uk-icon="heart"
        [ngStyle]="{ color: isFavorite ? '#fff' : '#ff6060' }"
      ></a>
    </div>

    <a
      *ngIf="produto"
      [routerLink]="['/product-details', produto.cod_produto]"
      [queryParams]="{
        desc: produto.descricao ? produto.descricao.toLowerCase() : '-',
        marca: produto.descricao_marca
          ? produto.descricao_marca.toLowerCase()
          : '-',
        sku: produto.sku
      }"
      class="uk-position-relative image"
    >
      <div
        *ngIf="produto.valor_venda < produto.preco_tabela && !produto.desconto"
        class="uk-position-bottom-left uk-overlay uk-overlay-default uk-background-primary uk-text-bolder uk-padding-small uk-light uk-border-circle"
        style="z-index: 9; background: #ff6a00; color: #ffffff"
      >
        OFERTA
      </div>
      <div
        *ngIf="produto.tipo_campanha === 33"
        class="uk-position-bottom-left uk-overlay uk-overlay-default uk-background-primary uk-text-bolder uk-padding-small uk-light uk-border-circle"
        style="z-index: 9"
      >
        PROMO BRINDE
      </div>
      <div class="imagem">
        <img
          *ngIf="produto"
          class="order-0"
          appImagemAuth
          [src]="
            'https://midias.guarany.com.br/jsleiman/private/fotos/medium/' +
            produto.codigo_barras +
            '_1.png'
          "
          [alt]="produto.descricao"
          onerror="this.src='assets/imagens/produto-sem-foto-30.png'"
        />

        <img
          *ngIf="!produto"
          class="order-0"
          src="assets/imagens/produto-sem-foto-30.png"
          alt=""
        />
        <div *ngIf="addLoadCart" style="position: relative; top: -4rem">
          <ngx-spinner
            bdColor="rgba(51,51,51,0.8)"
            name="mySpinner"
            bdColor="#ff8888"
          ></ngx-spinner>
        </div>
      </div>
    </a>

    <div style="display: flex; justify-content: start; width: 100%">
      <a
        [routerLink]="['/product-details', produto.cod_produto]"
        class="descricao-marca"
      >
        {{
          produto.descricao_marca == "null"
            ? "Sem descrição"
            : produto.descricao_marca
        }}
      </a>
    </div>

    <div style="display: flex; justify-content: start; width: 100%">
      <a
        [routerLink]="['/product-details', produto.cod_produto]"
        class="informação-produto"
      >
        {{ capitalizeSentence(produto.descricao) }}
      </a>
    </div>

    <div class="preco">
      <div
        *ngIf="produto.valor_venda < produto.preco_tabela && !produto.desconto"
        class="preco-max-grid"
      >
        <span class="preco-tabela">{{
          produto.preco_tabela | currency : "BRL"
        }}</span>
      </div>
      <span class="preco-tabela" *ngIf="produto.desconto">
        {{ produto.preco_tabela | currency }}
      </span>
      <a
        [routerLink]="['/product-details', produto.cod_produto]"
        class="preco-atual"
      >
        {{ produto.valor_venda | currency }}
      </a>
      <span class="parcelas">
        {{ produto.parcelamento }}
      </span>
    </div>

    <!-- <div class="descricao">{{ produto.detalhe_produto }}</div> -->

    <button
      *ngIf="produto.qt_estoque > 0 || produto.estoque > 0"
      [routerLink]="['/product-details', produto.cod_produto]"
      [queryParams]="{
        desc: produto.descricao ? produto.descricao.toLowerCase() : '-',
        marca: produto.descricao_marca
          ? produto.descricao_marca.toLowerCase()
          : '-',
        sku: produto.sku
      }"
      class="comprar d-none d-md-block"
    >
      Compre Agora
    </button>

    <!-- <button
      *ngIf="produto.qt_estoque === 0 || produto.estoque === 0"
      [routerLink]="['/product-details', produto.cod_produto]"
      [queryParams]="{
        desc: produto.descricao ? produto.descricao.toLowerCase() : '-',
        marca: produto.descricao_marca
          ? produto.descricao_marca.toLowerCase()
          : '-',
        sku: produto.sku
      }"
      class="sem-estoque"
    >
      Indisponível
    </button> -->

    <!-- *ngIf="produto.qt_estoque > 0" -->
    <button
      class="btn btn-link d-block d-md-none"
      style="background: #ff6060; color: #ffffff; border: 1px solid #ff606057"
      [routerLink]="['/product-details', produto.cod_produto]"
      [queryParams]="{
        desc: produto.descricao ? produto.descricao.toLowerCase() : '-',
        marca: produto.descricao_marca
          ? produto.descricao_marca.toLowerCase()
          : '-',
        sku: produto.sku
      }"
    >
      Compre Agora
    </button>

    <button
      *ngIf="produto.qt_estoque === 0 || produto.estoque === 0"
      class="btn"
      style="color: #ff6060; border: 1px solid #ff606057; z-index: 100000"
      (click)="aviseMe(produto.cod_produto)"
    >
      Avise-me
    </button>
  </div>
  <div class="div-produto-fp uk-padding-small" *ngIf="foraPadrao">
    <a
      [routerLink]="['/product-details', produto.cod_produto]"
      [queryParams]="{
        desc: produto.descricao ? produto.descricao.toLowerCase() : '-',
        marca: produto.descricao_marca
          ? produto.descricao_marca.toLowerCase()
          : '-',
        sku: produto.sku
      }"
      class="uk-position-relative image"
    >
      <div class="imagem">
        <img
          class="order-0"
          alt=""
          appImagemAuth
          [src]="
            'https://midias.guarany.com.br/jsleiman/private/fotos/medium/' +
            produto.codigo_barras +
            '_1.png'
          "
          onerror="this.src='assets/imagens/produto-sem-foto-30.png'"
        />
        <div *ngIf="addLoadCart" style="position: relative; top: -4rem">
          <ngx-spinner
            bdColor="rgba(51,51,51,0.8)"
            name="mySpinner"
            bdColor="#ff8888"
          ></ngx-spinner>
        </div>
      </div>
    </a>

    <a
      style="display: none"
      [routerLink]="['/product-details', produto.cod_produto]"
      class="informação-produto uk-text-bold"
    >
    </a>
  </div>
</ng-template>

<ng-template #cardIsLoading>
  <div class="cards">
    <div class="loading-card is-loading">
      <div class="image"></div>
      <div class="content">
        <h2></h2>
        <p></p>
      </div>
    </div>
  </div>
</ng-template>
