import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from './error-handler.service';
import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotifyStockService {
  constructor(
    private httpClient: HttpClient,
    private errorHandler: ErrorHandlerService
  ) {}

  addProductToWaitingList(cod_produto: string, email: string) {
    return this.httpClient
      .post(`${environment.api}/product/notify-product-stock`, {
        cod_produto: cod_produto,
        email: email,
      })
      .pipe(catchError(this.errorHandler.handleError));
  }
}
