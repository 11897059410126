import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IHomeStructure } from 'src/app/model/home.structure.model';

@Component({
  selector: 'app-banners-verticais',
  templateUrl: './banners-verticais.component.html',
  styleUrls: ['./banners-verticais.component.scss']
})

export class BannersVerticaisComponent implements OnInit {

  @Input() banners!: IHomeStructure[];

  constructor() { }

  ngOnInit(): void {

  }

}
