<div uk-slider="autoplay: true; autoplay-interval: 2000">
  <div class="uk-position-relative">
    <div class="uk-slider-container">
      <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-4@m uk-child-width-1-6@l uk-grid">
        <li style="min-height: 80px" *ngFor="let b of brands">
          <div class="uk-card card-marca uk-flex uk-flex-center uk-flex-middle" (click)="marcas(b.nome)">
            <div class="uk-panel">
              <img appImagemAuth class="imagem-marca" src="{{ b.src }}" height="80" width="auto" alt="" />
              <div class="uk-position-center uk-panel"></div>
            </div>
            <div class="titulo uk-text-bolder"></div>
          </div>
        </li>
      </ul>
    </div>
    <div class="uk-hidden@s uk-light">
      <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
      <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
    </div>
    <!-- <div class="uk-visible@s">
      <a
        class="uk-position-center-left-out uk-position-small"
        href="#"
        uk-slidenav-previous
        uk-slider-item="previous"
      ></a>
      <a
        class="uk-position-center-right-out uk-position-small"
        href="#"
        uk-slidenav-next
        uk-slider-item="next"
      ></a>
    </div> -->
  </div>
</div>