<form [formGroup]="formCartao" >
  <div class="form-row">

    <div class="col-md-12 form-group">
      <label class="form-label font-weight-bold small">Número do Cartão</label>
      <div class="input-group">
        <input formControlName="cartao" id="cartao" (blur)="getCardFlag($event)" placeholder="Só Números" type="text"
          mask="0000.0000.0000.0000" class="busca-cartao">
        <div class="bandeira-do-cartao col-md-4 form-group">
          <img *ngIf="bandeiraCartao"
          class="imagem-cartao"
          [src]="'../../../../assets/imagens/imagens-cartões/bandeira_' + bandeiraCartao.toLowerCase() + '.jpeg'"
          label class=" form-label font-weight-bold small">Bandeira
          do Cartão</div>
      </div>
    </div>

    <div class="col-md-8 form-group"><label class="form-label font-weight-bold small">Data
        de
        Validade(MM/AA)</label><input formControlName="validade" id="validade" placeholder="Data de Validade(MM/AA)"
        mask='99/99' type="text" class="form-control"></div>

    <div class="col-md-4 form-group"><label class="form-label font-weight-bold small">CVV</label><input
        placeholder="Número CVV" type="text" mask="999" formControlName="cvv" id="cvv" class="form-control">
      <div class="col-md-12 form-group">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" id="custom-checkbox1" class="custom-control-input">
          <label title="" formControlName="salvarCvv" type="checkbox" for="custom-checkbox1"
            class="custom-control-label small pt-1"> Salvar CVV</label>
        </div>
      </div>
    </div>


    <div class="col-md-12 form-group"><label class="form-label font-weight-bold small">Nome
        igual do Cartão</label><input formControlName="nome" id="nome" placeholder="Nome igual do Cartão" type="text"
        class="form-control"></div>

    <div class="col-md-12 form-group">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" id="custom-checkbox2" class="custom-control-input">
        <label title="" type="checkbox" for="custom-checkbox2" formControlName="salvar"
          class="custom-control-label small pt-1">Salve este cartão
          com segurança para
          próxima
          compra</label>
      </div>
    </div>
  </div>
</form>
