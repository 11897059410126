import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent {
  @ViewChild('modalContent', { read: ViewContainerRef })
  modalContent!: ViewContainerRef;

  constructor(public modalService: ModalService) {}

  ngAfterViewInit() {
    this.modalService.setViewContainerRef(this.modalContent);
  }

  closeModal() {
    this.modalService.destroyComponent();
  }
}
