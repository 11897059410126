<body class="fixed-bottom-padding">
  <app-breadcrumb
    [items]="[{
      label: 'Lista de Produtos'
    }]"
  ></app-breadcrumb>
  <!-- body -->
  <section class="p-4 osahan-main-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="osahan-listing">
            <div class="d-flex align-items-center mb-3">
              <h4>Lista de Produtos</h4>
              <div class="m-0 text-center ml-auto">
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  class="btn text-muted bg-white mr-2"
                  ><i class="icofont-filter mr-1"></i> Filtro</a
                >
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  class="btn text-muted bg-white"
                  ><i class="icofont-signal mr-1"></i> Ordenar</a
                >
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-md-3 mb-3" *ngFor="let p of products">
                <div
                  class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm"
                >
                  <div class="list-card-image">
                    <a [routerLink]="['/product-details']" class="text-dark">
                      <div class="member-plan position-absolute">
                        <span class="badge m-3 badge-danger">10%</span>
                      </div>
                      <div class="p-3 uk-text-center">
                        <img
                          src="{{ p.imagens[0] }}"
                          alt=""
                          onerror="this.src='assets/imagens/produto-sem-foto-30.png'"
                        />
                        <h6>{{ p.descricao }}</h6>
                        <div class="d-flex align-items-center">
                          <h6 class="price m-0 text-success uk-text-bolder">
                            {{ p.preco_tabela }}
                          </h6>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div
    class="modal fade right-modal"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Filtro</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="osahan-filter">
            <div class="filter">
              <!-- SORT BY -->
              <div class="p-3 bg-light border-bottom">
                <h6 class="m-0">Ordenar Por</h6>
              </div>
              <div class="custom-control border-bottom px-0 custom-radio">
                <input
                  type="radio"
                  id="customRadio1"
                  name="location"
                  class="custom-control-input"
                  checked
                />
                <label
                  class="custom-control-label py-3 w-100 px-3"
                  for="customRadio1"
                  >Mais Vendidos</label
                >
              </div>
              <div class="custom-control border-bottom px-0 custom-radio">
                <input
                  type="radio"
                  id="customRadio2"
                  name="location"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label py-3 w-100 px-3"
                  for="customRadio2"
                  >Novidades</label
                >
              </div>
              <div class="custom-control border-bottom px-0 custom-radio">
                <input
                  type="radio"
                  id="customRadio3"
                  name="location"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label py-3 w-100 px-3"
                  for="customRadio3"
                  >Menores Preços</label
                >
              </div>
              <div class="custom-control border-bottom px-0 custom-radio">
                <input
                  type="radio"
                  id="customRadio4"
                  name="location"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label py-3 w-100 px-3"
                  for="customRadio4"
                  >Maiores Preços</label
                >
              </div>
              <div class="custom-control border-bottom px-0 custom-radio">
                <input
                  type="radio"
                  id="customRadio5"
                  name="location"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label py-3 w-100 px-3"
                  for="customRadio5"
                  >Maiores Descontos</label
                >
              </div>
              <!-- Filter -->
              <div class="p-3 bg-light border-bottom">
                <h6 class="m-0">Filtro</h6>
              </div>
              <div class="p-3 bg-light border-bottom">
                <h6 class="m-0">ADICIONAR FILTRO</h6>
              </div>
              <div class="px-3 pt-3">
                <input
                  type="range"
                  class="custom-range"
                  min="0"
                  max="100"
                  name=""
                />
                <div class="form-row">
                  <div class="form-group col-6">
                    <label>Mínimo</label>
                    <input
                      class="form-control"
                      placeholder="R$0"
                      type="number"
                    />
                  </div>
                  <div class="form-group text-right col-6">
                    <label>Máximo</label>
                    <input
                      class="form-control"
                      placeholder="R$1.000,00"
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-0 border-0">
          <div class="col-6 m-0 p-0">
            <button
              type="button"
              class="btn border-top btn-lg btn-block"
              data-dismiss="modal"
            >
              Fechar
            </button>
          </div>
          <div class="col-6 m-0 p-0">
            <button type="button" class="btn btn-success btn-lg btn-block">
              Aplicar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
