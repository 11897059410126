import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  take,
  throwError,
  timeout,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorService } from './error.service';
import { IAccount } from '../../model/account.model';
import UIkit from 'uikit';
import { CarrinhoService } from './carrinho.service';
import { FirebaseService } from './firebase.service';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
} from 'firebase/auth';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class SocialLoginService {
  isLogged!: boolean;
  accountData: BehaviorSubject<any> = new BehaviorSubject(undefined);

  firebaseConfig: FirebaseOptions = {
    apiKey: 'AIzaSyDK7Qr-So6IRzQOENr1kM4yma4QZZJ0Iis',
    authDomain: 'exalla-ecommerce.firebaseapp.com',
    databaseURL: 'https://exalla-ecommerce.firebaseio.com',
    projectId: 'exalla-ecommerce',
    storageBucket: 'exalla-ecommerce.appspot.com',
    messagingSenderId: '834807660930',
    appId: '1:834807660930:web:eae951451a9d08d4854f9b',
    measurementId: 'G-R42XBCSNCN',
  };

  constructor(
    // private angularFireAuth: AngularFireAuth,
    private httpClient: HttpClient,
    private errorService: ErrorService,
    private carrinho: CarrinhoService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  setAccount(account: IAccount) {
    // sessionStorage.setItem('@account_data', JSON.stringify(account))
  }

  getPermissionToRegister(document: string): Observable<any> {
    return this.httpClient.post<any>(`${environment.api}/account/register`, {
      cpf_cnpj: document,
      info: true,
    });
  }

  getProfissoes() {
    return this.httpClient
      .get<any>(`${environment.api}/account/profissoes`)
      .pipe(take(3), timeout(5000));
  }

  getAccount(): Observable<IAccount> {
    const token = localStorage.getItem('@access_token');
    const _headers = {
      Authorization: 'Bearer ' + token,
    };
    return this.httpClient.get<IAccount>(`${environment.api}/account/me`, {
      headers: _headers,
    });
  }

  signUp(data: any) {
    const isNameValid = data.name.split(' ');

    // if (isNameValid.length <= 1) {
    //   UIkit.notification({
    //     message: 'Preencher o campo Nome e Sobrenome',
    //     timeout: 1000,
    //     pos: 'top-center',
    //   });

    //   return;
    // }
    // if (isNameValid.length <= 1) {
    //   UIkit.notification({
    //     message: 'preencher o campo Nome_social',
    //     timeout: 1000,
    //     pos: 'top-center',
    //   });

    //   return;
    // }
    // if (isNameValid.length <= 1) {
    //   UIkit.notification({
    //     message: 'Selecione Seu gênero',
    //     timeout: 1000,
    //     pos: 'top-center',
    //   });

    //   return;
    // }

    return this.httpClient
      .post(`${environment.api}/account/register`, {
        provider: 'credentials',
        ...data,
      })
      .pipe(catchError(this.handleError));
  }

  signIn(
    type: 'google' | 'facebook' | 'credentials',
    data?: any,
    callback?: any
  ) {
    try {
      let provider = undefined;
      let codCarrinho = '';
      if (localStorage.getItem('cod_carrinho') !== null) {
        codCarrinho = localStorage.getItem('cod_carrinho')!;
      }
      data.cod_carrinho = codCarrinho;
      if (type === 'facebook') {
        provider = new FacebookAuthProvider();
      } else if (type === 'google') {
        provider = new GoogleAuthProvider();
      }

      if (type !== 'credentials' && provider) {
        const app = initializeApp(this.firebaseConfig);
        const auth = getAuth(app);
        signInWithPopup(auth, provider)
          .then((data: any) => {
            let access_token = undefined;
            if (type === 'google') {
              const credential = GoogleAuthProvider.credentialFromResult(data);
              access_token = credential?.idToken;
            } else if (type === 'facebook') {
              access_token = data._tokenResponse.oauthAccessToken;
            }
            this.back({
              provider: data.providerId,
              token: access_token,
              name: data.user?.displayName,
              uid: data.user?.uid,
              email: data.email ? data.email : data.user.email,
              foto_perfil: data.user.photoURL,
            }).subscribe({
              next: (value: any) => {
                this.accountData.next(value.account);
                localStorage.setItem('@access_token', value.access_token);
                this.isLogged = true;

                sessionStorage.setItem(
                  '@account_data',
                  JSON.stringify(value.account as IAccount)
                );

                if (
                  value.account.cpf_cnpj === null ||
                  value.account.phone === null ||
                  value.account.cpf_cnpj === '' ||
                  value.account.phone === '' ||
                  value.account.cadastro_completo === 0
                ) {
                  this.router.navigate(['/minha-conta']);
                  UIkit.notification({
                    message:
                      'Por favor, atualize os dados faltantes para poder finalizar suas compras',
                    timeout: 10000,
                    status: 'warning',
                  });
                } else {
                  this.router.navigate(['']);
                  UIkit.notification({
                    message: 'Login efetuado com sucesso.',
                    timeout: 10000,
                    status: 'warning',
                  });
                }
                this.spinner.hide('signUp');
              },
              error: (error: any) => {
                this.spinner.hide('signUp');
                this.errorService.setError({
                  name: error.message,
                  statusCode: error.statusCode,
                });
              },
              complete: () => {
                this.spinner.hide('signIn');
              },
            });
          })
          .catch((error) => {
            this.spinner.hide('signUp');
            let errorMessage = '';
            if (error.message.includes('popup-closed-by-user')) {
              errorMessage = 'Por favor, autorize os popups no seu navegador.';
            } else {
              errorMessage = 'Falha no login com a rede social selecionada.';
            }
            this.spinner.hide('signIn');
            UIkit.notification({
              message: errorMessage,
              timeout: 5000,
              status: 'danger',
            });
          });
      } else {
        this.back({
          provider: 'credentials',
          ...data,
        }).subscribe({
          next: (value: any) => {
            if (value.status === false) {
              this.spinner.hide('signIn');
              UIkit.notification({
                message: value.message,
                timeout: 2000,
                status: 'warning',
                pos: 'top-center',
              });
            } else if (
              value.account.cpf_cnpj === null ||
              value.account.phone === null ||
              value.account.genero === null ||
              value.account.cpf_cnpj === '' ||
              value.account.phone === '' ||
              value.account.genero === '' ||
              value.account.cadastro_completo === 0
            ) {
              this.spinner.hide('signIn');
              localStorage.setItem('@access_token', value.access_token);
              this.accountData.next(value.account);
              this.router.navigate(['/minha-conta']);
              UIkit.notification({
                message:
                  'Por favor, atualize os dados faltantes para poder finalizar suas compras',
                timeout: 10000,
                status: 'danger',
              });
            } else {
              this.spinner.hide('signIn');
              localStorage.setItem('@access_token', value.access_token);
              this.accountData.next(value.account);
              this.getCarrinho();
              sessionStorage.setItem(
                '@account_data',
                JSON.stringify(value.account)
              );
              this.router.navigate(['']);
              UIkit.notification({
                message: 'Login efetuado com sucesso.',
                timeout: 10000,
                status: 'danger',
              });
              callback(value);
            }
          },
          error: (error) => {
            this.spinner.hide('signIn');
            this.errorService.setError({
              name: error.message,
              statusCode: error.statusCode,
            });

            // this.spinner.hide('signIn');
          },
          complete: () => {
            this.spinner.hide('signIn');
          },
        });
      }
    } catch (error) {
      this.spinner.hide('signUp');
    }
  }

  getCarrinho() {
    this.carrinho.returnCarrinho().subscribe({
      next: (resposta) => {
        this.carrinho.carrinhoSubject.next(resposta);
      },
    });
  }

  private back(data?: any) {
    return this.httpClient
      .post(`${environment.api}/account/login`, data)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    const errorResponse = {
      message: error.error.message,
      statusCode: error.error.statusCode,
      timestamp: error.error.timestamp,
      errors: error.error.messages ?? [],
      realStatusCode: error.status,
    };

    return throwError(() => errorResponse);
  }

  retrieveToken(): string | null {
    return localStorage.getItem('@access_token');
  }

  persist(key: string, value: any) {
    if (value !== null && value !== undefined) {
      localStorage.setItem(key, value);
    }
  }

  isAuthenticated(): boolean {
    return localStorage.getItem('@access_token') ? true : false;
  }

  logOut() {
    this.isLogged = false;
    localStorage.removeItem('@access_token');
    localStorage.removeItem('cod_carrinho');
    this.accountData.next(undefined);
    this.router.navigate(['']);
    window.location.reload();
  }

  clearToken() {
    this.isLogged = false;
    localStorage.removeItem('@access_token');
    localStorage.removeItem('cod_carrinho');
    this.accountData.next(undefined);
  }
}
