import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appRestrictSpecialChars]',
})
export class RestrictSpecialCharsDirective {
  private regex: RegExp = new RegExp(/^[a-zA-Z0-9 ]*$/g);

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const input = this.el.nativeElement as HTMLInputElement;
    const sanitizedValue = input.value.replace(/[^a-zA-Z0-9çÇ ]/g, '');
    input.value = sanitizedValue;
  }
}
