<div class="fixed-bottom-padding">
  <app-breadcrumb
    [items]="[
      {
        label: 'Ofertas Imperdíveis'
      }
    ]"
  ></app-breadcrumb>
  <!-- body -->
  <section class="p-4 osahan-main-body">
    <div class="container">
      <div class="uk-grid uk-child-width-1-2 uk-child-width-1-6@m" uk-grid>
        <div>
          <div class="osahan-listing">
            <div class="d-flex align-items-center mb-3">
              <h4>Ofertas Imperdíveis</h4>
              <div class="m-0 text-center ml-auto">
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  class="btn text-muted bg-white mr-2"
                  ><i class="icofont-filter mr-1"></i> Filtro</a
                >
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  class="btn text-muted bg-white"
                  ><i class="icofont-signal mr-1"></i> Ordenar Por</a
                >
              </div>
            </div>
            <app-pick-slide></app-pick-slide>
            <app-pick-slide></app-pick-slide>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade right-modal"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Filtro</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0">
            <div class="osahan-filter">
              <div class="filter">
                <!-- SORT BY -->
                <div class="p-3 bg-light border-bottom">
                  <h6 class="m-0">Ordenar Por</h6>
                </div>
                <div class="custom-control border-bottom px-0 custom-radio">
                  <input
                    type="radio"
                    id="customRadio1"
                    name="location"
                    class="custom-control-input"
                    checked
                  />
                  <label
                    class="custom-control-label py-3 w-100 px-3"
                    for="customRadio1"
                    >Mais Vendidos</label
                  >
                </div>
                <div class="custom-control border-bottom px-0 custom-radio">
                  <input
                    type="radio"
                    id="customRadio2"
                    name="location"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label py-3 w-100 px-3"
                    for="customRadio2"
                    >Novidades</label
                  >
                </div>
                <div class="custom-control border-bottom px-0 custom-radio">
                  <input
                    type="radio"
                    id="customRadio3"
                    name="location"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label py-3 w-100 px-3"
                    for="customRadio3"
                    >Menores Preços</label
                  >
                </div>
                <div class="custom-control border-bottom px-0 custom-radio">
                  <input
                    type="radio"
                    id="customRadio4"
                    name="location"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label py-3 w-100 px-3"
                    for="customRadio4"
                    >Maiores Preços</label
                  >
                </div>
                <div class="custom-control border-bottom px-0 custom-radio">
                  <input
                    type="radio"
                    id="customRadio5"
                    name="location"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label py-3 w-100 px-3"
                    for="customRadio5"
                    >Maiores Descontos</label
                  >
                </div>
                <!-- Filter -->
                <div class="p-3 bg-light border-bottom">
                  <h6 class="m-0">Filtro</h6>
                </div>
                <!-- <div class="custom-control border-bottom px-0  custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="defaultCheck1" checked>
                        <label class="custom-control-label py-3 w-100 px-3" for="defaultCheck1">Open Now</label>
                     </div>
                     <div class="custom-control border-bottom px-0  custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="defaultCheck2">
                        <label class="custom-control-label py-3 w-100 px-3" for="defaultCheck2">Credit Cards</label>
                     </div>
                     <div class="custom-control border-bottom px-0  custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="defaultCheck3">
                        <label class="custom-control-label py-3 w-100 px-3" for="defaultCheck3">Alcohol Served</label>
                     </div> -->
                <!-- Filter -->
                <div class="p-3 bg-light border-bottom">
                  <h6 class="m-0">ADICIONAR FILTRO</h6>
                </div>
                <div class="px-3 pt-3">
                  <input
                    type="range"
                    class="custom-range"
                    min="0"
                    max="100"
                    name=""
                  />
                  <div class="form-row">
                    <div class="form-group col-6">
                      <label>Mínimo</label>
                      <input
                        class="form-control"
                        placeholder="$0"
                        type="number"
                      />
                    </div>
                    <div class="form-group text-right col-6">
                      <label>Máximo</label>
                      <input
                        class="form-control"
                        placeholder="$1,0000"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer p-0 border-0">
            <div class="col-6 m-0 p-0">
              <button
                type="button"
                class="btn border-top btn-lg btn-block"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
            <div class="col-6 m-0 p-0">
              <button type="button" class="btn btn-success btn-lg btn-block">
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
