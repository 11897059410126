import {
  AfterViewInit,
  Component,
  Input,
  ChangeDetectorRef,
  OnInit,
  AfterContentInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import UIkit from 'uikit';
import { CarrinhoService } from '../../services/carrinho.service';
import { ProductData } from '../../../model/carrinho';
import { FavoritesService } from '../../services/favorites.service';
import { iCartDefaultResponse } from 'src/app/views/cart/interfaces/cart.interface';
import { SocialLoginService } from '../../services/social-login.service';
import { NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NotifyStockService } from '../../services/notifyStock.service';

@Component({
  selector: 'app-produto',
  templateUrl: './produto.component.html',
  styleUrls: ['./produto.component.scss'],
})
export class ProdutoComponent
  implements AfterViewInit, OnInit, AfterContentInit, OnChanges
{
  @Input() produto: any;
  @Input() foraPadrao: boolean = false;
  isFavorite: boolean = false;
  logado: boolean = false;
  imgLoaded: boolean = false;

  addLoadCart: boolean = false;

  constructor(
    private carrinhoService: CarrinhoService,
    public favorite: FavoritesService,
    public login: SocialLoginService,
    public router: Router,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private notifStockService: NotifyStockService
  ) {
    this.logado = this.login.isLogged;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['produto'].currentValue != changes['produto'].previousValue) {
    }
    // this.router.events
    //   .pipe(
    //     filter((event) => event instanceof NavigationEnd),
    //     map((event) => event as NavigationEnd),
    //     filter((event) => event.url !== '/employees')
    //   )
    //   .subscribe((event) => {
    //     return;
    //   });
  }

  ngAfterContentInit(): void {
    // this.cdr.detectChanges();
  }
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isFavorite = this.produto.favorito ? this.produto.favorito : false;
    }, 500);
    // this.cdr.detectChanges();
    // if (this.favorite.favoritos) {
    //   if (this.favorite.favoritos.filter((p: any) => p.cod_produto == this.produto.cod_produto).length > 0) {
    //     this.isFavorite = true;
    //   }
    // }
  }

  addCart(cod_produto: string, quantity: number) {
    this.loadAddCart(true);
    this.carrinhoService.createProduct(cod_produto, quantity, '+').subscribe({
      next: (value: any) => {
        localStorage.setItem('cod_carrinho', value.cod_carrinho);
        this.carrinhoService.carrinhoSubject.next(value);
        UIkit.notification({
          message: 'Produto adicionado no Carrinho',
          timeout: 900,
          pos: 'bottom-center',
          status: 'success',
        });
      },
      error: (error) => {
        if (error.error.message === 'cart/index.invalid-permission') {
          localStorage.removeItem('cod_carrinho');
          UIkit.notification({
            message: 'Falha ao adicionar produto',
            timeout: 900,
            pos: 'bottom-center',
            status: 'danger',
          });
        }
      },
      complete: () => this.loadAddCart(false),
    });
  }

  capitalizeSentence = (text: string) => {
    if (text) {
      return text
        .split(' ')
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ');
    } else {
      return ' ';
    }
  };

  favoritar(produto: any, e: any) {
    if (!localStorage.getItem('@access_token')) {
      this.router.navigate(['/signin']);
      return;
    }
    if (this.isFavorite === false) {
      this.isFavorite = true;
      this.favorite.addFavorite(produto.cod_produto).subscribe({
        next: (response: any) => {
          // e.target.parentNode.classList.add('favorito');
          // e.target.parentNode.classList.remove('uk-background-default');
          if (response.status == true) {
            UIkit.notification({
              message: 'Produto adicionado aos favoritos',
              timeout: 900,
              pos: 'bottom-center',
            });
          }
        },
      });
    } else {
      this.favorite.removeFavorite(produto.cod_produto).subscribe({
        next: (response: any) => {
          // e.target.parentNode.classList.remove('favorito');
          // e.target.parentNode.classList.add('uk-background-default');
          this.isFavorite = false;
          if (response.status == true) {
            UIkit.notification({
              message: 'Produto removido aos favoritos',
              timeout: 900,
              pos: 'bottom-center',
            });
          }
        },
        error: () => {
          this.isFavorite = false;
        },
      });
    }
  }

  aviseMe(cod_produto: string) {
    if (this.login.accountData.value == undefined) {
      UIkit.notification({
        message:
          'Você precisa fazer login para ser avisado sobre esse produto!',
        timeout: 3000,
        pos: 'bottom-center',
      });
      return;
    }
    this.notifStockService
      .addProductToWaitingList(cod_produto, this.login.accountData.value.email)
      .subscribe({
        next: (res: any) => {
          UIkit.notification({
            message: res.message,
            timeout: 3000,
            pos: 'bottom-center',
          });
        },
        error: (error: any) => {
          UIkit.notification({
            message:
              'Ocorreu um erro ao adicionar o produto a sua lista de espera, por favor, tente novamente em alguns minutos!',
            timeout: 3000,
            pos: 'bottom-center',
          });
        },
      });
  }

  getProductParcel() {
    if (
      this.produto.valor_venda >= 80 &&
      this.produto.valor_venda < 120 &&
      this.produto.cod_subgrupo !== 1001
    ) {
      return (
        '  2x de ' +
        (this.produto.valor_venda / 2).toLocaleString('PT-BR', {
          currency: 'BRL',
          style: 'currency',
        })
      );
    }
    if (
      this.produto.valor_venda >= 120 &&
      this.produto.valor_venda < 200 &&
      this.produto.cod_subgrupo !== 1001
    ) {
      return (
        ' 3x de ' +
        (this.produto.valor_venda / 3).toLocaleString('PT-BR', {
          currency: 'BRL',
          style: 'currency',
        })
      );
    }
    if (
      this.produto.valor_venda >= 200 &&
      this.produto.valor_venda < 300 &&
      this.produto.cod_subgrupo !== 1001
    ) {
      return (
        ' 4x de ' +
        (this.produto.valor_venda / 4).toLocaleString('PT-BR', {
          currency: 'BRL',
          style: 'currency',
        })
      );
    }
    if (this.produto.valor_venda >= 300 && this.produto.valor_venda < 300) {
      return (
        '  5x de ' +
        (this.produto.valor_venda / 5).toLocaleString('PT-BR', {
          currency: 'BRL',
          style: 'currency',
        })
      );
    }

    if (
      this.produto.valor_venda >= 1000 &&
      this.produto.cod_subgrupo !== 1001
    ) {
      return (
        '  10x de ' +
        (this.produto.valor_venda / 10).toLocaleString('PT-BR', {
          currency: 'BRL',
          style: 'currency',
        })
      );
    }
    return '';
  }

  descLink(str: string) {
    return str.toLowerCase().replace(/\s/g, '-');
  }

  loadAddCart(value: boolean) {
    if (value === true) {
      this.addLoadCart = true;
      this.spinner.show('mySpinner', {
        size: 'large',
        color: 'white',
        type: 'ball-scale-multiple',
        fullScreen: false,
      });
    } else {
      this.addLoadCart = false;
      this.spinner.hide();
    }
  }

  marcas(descricao_marca: string) {
    this.router.navigate(['/produtos'], {
      queryParams: {
        descricao_marca,
      },
    });
  }
}
