import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, Subject, take, timeout } from 'rxjs';
import { iCartDefaultResponse, iProduct } from 'src/app/views/cart/interfaces/cart.interface';
import { ErrorHandlerService } from './error-handler.service';


@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  favoritos!: iProduct[];

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) { }

  getFavorite(request: any) {
    return this.http.post(`${environment.api}/product/favorite/list`, request)
      .pipe(take(2), timeout(5000), catchError(this.errorHandler.handleError));
  }

  addFavorite(cod_produto: string) {
    return this.http.post(`${environment.api}/product/favorite`, { cod_produto: cod_produto })
      .pipe(take(2), timeout(5000), catchError(this.errorHandler.handleError));
  }

  removeFavorite(cod_produto: string) {
    return this.http.delete(`${environment.api}/product/favorite`, { body: { cod_produto: cod_produto } })
      .pipe(take(2), timeout(5000), catchError(this.errorHandler.handleError));
  }
}
