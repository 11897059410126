import { CarrinhoService } from './../../services/carrinho.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IHomeBrand, ISimpleBrand } from 'src/app/model/home.structure.model';
import { Router } from '@angular/router';
import { SocialLoginService } from '../../services/social-login.service';
import { marcas } from 'src/app/misc/marcasSelecionadas';

@Component({
  selector: 'app-recommend-slide',
  templateUrl: './recommend-slide.component.html',
  styleUrls: ['./recommend-slide.component.scss'],
})
export class RecommendSlideComponent implements OnInit {
  // brands: IHomeBrand[] = [];
  brands: ISimpleBrand[] = [];

  constructor(private httpClient: HttpClient, private router: Router) {}

  ngOnInit(): void {
    // this.httpClient.post(`${environment.api}/home/brand`,[])
    //   .subscribe({
    //     next: (value: any) => {
    //       this.brands = value;
    //     }
    //   })
    this.arrayMarcas();
  }

  arrayMarcas() {
    for (let m of marcas) {
      this.brands.push({
        nome: this.capitalize(m.split('.')[0]),
        src: environment.imgServer + 'exalla/150/marcas/' + m,
      });
    }
  }

  marcas(descricao_marca: string) {
    this.router.navigate(['/produtos'], {
      queryParams: {
        descricao_marca,
      },
    });
  }

  capitalize(nome: string) {
    let palavra = [];
    for (let n of nome.split('_')) {
      palavra.push(n.charAt(0).toLocaleUpperCase() + n.slice(1));
    }
    return palavra.join(' ');
  }
}
