import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProdutoService } from 'src/app/service/produto.service';

type BreadcrumbItem = {
  label: string;
  route?: string;
  queryParams?: any;
};

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() items: BreadcrumbItem[] = [];
  @Input() hasFilter = false;

  @Output() onFilter = new EventEmitter<any>();

  isUserFiltering = false

  constructor(public router: Router, private location: Location, public productService: ProdutoService) { }

  goBack() {
    this.location.back();
  }

  order(event: any) {
    this.isUserFiltering = true
    this.onFilter.emit(event);
  }

  getBreadcrumbs(b: BreadcrumbItem, i: number) {
    if (i === 2) {
      let array = [];
      array.push(
        this.items[1].queryParams,
        b.queryParams
      )
      let newArray: any = array.reduce((acc, cur) => {
        const a = Object.assign({}, acc, cur)
        return a
      }, {})
      return newArray
    }
    return b.queryParams ? b.queryParams : []
  }
}
