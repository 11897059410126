import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { SocialLoginService } from '../services/social-login.service';
import { environment } from 'src/environments/environment';
import UIkit from 'uikit';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(
    private socialLoginService: SocialLoginService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (localStorage.getItem('@access_token') !== null) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('@access_token')}`
        }
      })
    }

    return next.handle(request).pipe(
      catchError((error) => {

        // if (error.status > 204 || (!error.url.includes('home') && !error.url.includes('produtos') && !error.url.includes('department') && !error.url.includes('brand') && !error.url.includes('product'))) {
        //   this.socialLoginService.logOut();
        //   UIkit.notification({
        //     message: 'Token expirado. Faça login novamente.',
        //     pos: 'bottom-center',
        //     status: 'warning'
        //   })
        // }

        if (error.status === 401) {
          this.socialLoginService.isLogged = false;
          localStorage.removeItem('@access_token');
          this.socialLoginService.accountData.next(undefined);
          UIkit.notification({
            message: 'Faça login novamente.',
            pos: 'top-center',
            status: 'warning',
            timeout: 5000
          })
        }
        throw error;
      })
    );
  }
}
