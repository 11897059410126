import { Component } from '@angular/core';

@Component({
  selector: 'app-whatsapp-float-button',
  templateUrl: './whatsapp-float-button.component.html',
  styleUrls: ['./whatsapp-float-button.component.scss'],
})
export class WhatsappFloatButtonComponent {
  
 }
